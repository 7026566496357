import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';
import { setAuthorization } from '../../helpers/api_helper';
import { me } from '../../api/auth';
import { logoutUser, updateDetails } from '../../store/actions';
import { LoginTypes } from '../../constants/common';

function Authmiddleware(props) {
  const dispatch = useDispatch();
  const history = useNavigate();
  const { details } = useSelector((state) => ({
    details: state.Login.details,
  }));
  const token = JSON.parse(localStorage.getItem('authUser'))?.token;
  const onlyLoginTypes = props?.loginType ?? Object.values(LoginTypes);
  const loginType = JSON.parse(localStorage.getItem('authUser'))?.login_type;
  useEffect(() => {
    if (!details && token) {
      setAuthorization(token);
      me().then((response) => {
        dispatch(updateDetails({ ...response.data, token }));
      }).catch((err) => {
        console.error(err);
        dispatch(logoutUser(history, { login_type: loginType }));
      });
    }
  }, [token, details]);

  if (!details) {
    return <></>
  }

  if (token && onlyLoginTypes.length > 0 && !onlyLoginTypes.includes(loginType)) {
    history(-1);
    return <></>
  }

  if (!token && !details) {
    return (
      <Navigate to={{ pathname: '/startup-login' }} />
    );
  }

  return (
    <>
      {props.children}
    </>
  );
}

export default Authmiddleware;
Authmiddleware.propTypes = {
  children: PropTypes.any,
  loginType: PropTypes.array
};
