/* eslint-disable radix */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
// import {
//   Card, CardBody, CardTitle, CardText, Button,
// } from 'reactstrap';
import { Autocomplete, Checkbox, TextField } from '@mui/material';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import ClearIcon from '@mui/icons-material/Clear';
import toast from 'react-hot-toast';
import { updateStartup } from '../../../api/startup';
import {
  AllowedStartupIndustry, AllowedStartupModules, AllowedStartupModulesList, AllowedStartupRaisingAmount, AllowedStartupRegion, AllowedStartupStage,
} from '../../../constants/startup';
import { me } from '../../../api/auth';
import { updateDetails } from '../../../store/actions';

function StartupOnboarding() {
  const [isDisable, setIsDisable] = useState(true);
  const [otherIndustry, setOtherIndustry] = useState();
  const [startupUpdateLoading, setStartupUpdateLoading] = useState(false);
  const [trigger, setTrigger] = useState(new Date());
  const [numberOfFounders, setNumberOfFounders] = useState();
  const [memberName, setMemberName] = useState();
  const dispatch = useDispatch();

  document.title = 'Startup Form';

  const { details } = useSelector((state) => ({
    details: state.Login.details,
  }));
  const months = {
    jan: '',
    feb: '',
    mar: '',
    apr: '',
    may: '',
    jun: '',
    jul: '',
    aug: '',
    sep: '',
    oct: '',
    nov: '',
    dec: '',
  };
  const startupObject = {
    name: '',
    phone_number: '',
    region: '',
    industry: {},
    problem: '',
    description: '',
    stage: AllowedStartupStage.MVP,
    raising_amount: AllowedStartupRaisingAmount.ANGEL_ROUND,
    valuation: '',
    teams: [],
    founder_name: '',
    modules: [
      {
        type: AllowedStartupModules.VISITORS,
        ...months,
      },
      {
        type: AllowedStartupModules.MMR,
        ...months,
      },
      {
        type: AllowedStartupModules.BURN_RATE,
        ...months,
      },
      {
        type: AllowedStartupModules.CAC,
        ...months,
      },
    ],
  };

  const phoneRegExp = /^\+?[0-9]([0-9]|(-(?!-)))*[0-9]$/;
  const startupValidation = Yup.object().shape({
    name: Yup.string()
      .strict(true)
      .trim('Must not include leading and trailing spaces')
      .max(250, 'First name can not be greater than 250 characters')
      .required('Please enter name of the organization'),
    phone_number: Yup.string()
      .strict(true)
      .required('Please enter phone number ')
      .matches(phoneRegExp, 'Phone number is not valid'),
    region: Yup.string()
      .strict(true)
      .trim('Must not include leading and trailing spaces')
      .max(250, 'Region can not be greater than 250 characters')
      .required('Please enter region'),
    problem: Yup.string()
      .strict(true)
      .trim('Must not include leading and trailing spaces')
      .required('Please enter problem'),
    description: Yup.string()
      .strict(true)
      .trim('Must not include leading and trailing spaces')
      .required('Please enter description'),
    stage: Yup.string()
      .strict(true)
      .trim('Must not include leading and trailing spaces')
      .required('Please enter stage'),
    raising_amount: Yup.string()
      .strict(true)
      .trim('Must not include leading and trailing spaces')
      .required('Please select amount'),
    founder_name: Yup.string()
      .strict(true)
      .trim('Must not include leading and trailing spaces')
      .required('Please enter founder name'),
    valuation: Yup.number().required('Please enter valuation')
      .moreThan(0, 'Valuation cannot be 0 or negative'),
    teams: Yup.array().min(1).required(),
    industry: Yup.object(),
    modules: Yup.array().of(Yup.object().shape({
      type: Yup.string()
        .strict(true)
        .trim('Must not include leading and trailing spaces')
        .max(250, 'Too Long!')
        .required(),
      jan: Yup.number()
        .moreThan(0, 'Cost cannot be 0 or negative'),
      feb: Yup.number()
        .moreThan(0, 'Cost cannot be 0 or negative'),
      mar: Yup.number()
        .moreThan(0, 'Cost cannot be 0 or negative'),
      april: Yup.number()
        .moreThan(0, 'Cost cannot be 0 or negative'),
      may: Yup.number()
        .moreThan(0, 'Cost cannot be 0 or negative'),
      jun: Yup.number()
        .moreThan(0, 'Cost cannot be 0 or negative'),
      jul: Yup.number()
        .moreThan(0, 'Cost cannot be 0 or negative'),
      aug: Yup.number()
        .moreThan(0, 'Cost cannot be 0 or negative'),
      sep: Yup.number()
        .moreThan(0, 'Cost cannot be 0 or negative'),
      oct: Yup.number()
        .moreThan(0, 'Cost cannot be 0 or negative'),
      nov: Yup.number()
        .moreThan(0, 'Cost cannot be 0 or negative'),
      dec: Yup.number()
        .moreThan(0, 'Cost cannot be 0 or negative'),
    })).min(1)
      .max(10),
  });

  const formik = useFormik({
    initialValues: {
      ...startupObject,
    },
    validationSchema: startupValidation,
    validateOnMount: true,
  });

  const handleKeyDown = (event) => {
    if (event.keyCode === 8 || event.keyCode === 13) {
      return;
    }
    const { value } = event.target;
    const regex = /^\d*\.?\d{0,2}$/;

    if (!regex.test(value + event.key)) {
      event.preventDefault();
    }
  };

  const handleUpdateIndustry = (module, value) => {
    let data = formik.values.industry;
    if (value) {
      if (module === AllowedStartupIndustry.OTHERS) {
        data = {
          ...data,
          [module]: '',
        };
      } else {
        data = {
          ...data,
          [module]: module,
        };
      }
    } else {
      delete data[module];
    }
    formik.setFieldValue('industry', data);
  };

  const handleAddRow = () => {
    if (!memberName || memberName === '') {
      return toast.error('Please enter member name');
    }
    const data = formik.values.teams;
    data.push(memberName);
    formik.setFieldValue('teams', data);
    setMemberName();
    setTrigger(+new Date());
    return '';
  };

  const handleDeleteRow = (indexToDelete) => {
    const updatedRows = formik.values.teams.filter((_, index) => index !== indexToDelete);
    formik.setFieldValue('teams', updatedRows);
  };

  useEffect(() => {
    if (!numberOfFounders || parseInt(numberOfFounders) === formik.values.teams.length) {
      setIsDisable(true);
    } else {
      setIsDisable(false);
    }
  }, [numberOfFounders, JSON.stringify(formik.values.teams)]);

  const handleUpdate = async () => {
    try {
      const d = new Date();
      const year = d.getFullYear();
      const modules = formik.values.modules.map((module) => Object.fromEntries(Object.entries(module).filter(([_, v]) => v !== '')));
      const requiredPayload = {
        ...formik.values,
        industry: formik.values.industry,
        year,
        modules,
      };

      setStartupUpdateLoading(true);
      const response = await updateStartup(details?.details?.id, requiredPayload);
      if (response.statusCode === 200) {
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }

      setStartupUpdateLoading(false);
      const res = await me();
      dispatch(updateDetails({ ...res.data, token: details.details.token }));
    } catch (error) {
      console.log(error);
      toast.error(error.message);
    } finally {
      setStartupUpdateLoading(false);
    }
  };

  return (
    <div className="page-content">
      <div className="d-flex justify-content-center align-items-center mb-3">
        <span>
          <svg width="36" height="26" viewBox="0 0 36 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.72065 4.5004C13.3207 -1.8996 22.2206 0.500403 25.7206 2.5004C14.9206 3.30073 9.88733 10.8336 8.7207 14.5C14.7207 8 21.7207 5.5 29.7207 6C37.7207 6.5 35.7207 13.5 33.7207 18C32.1125 21.6185 31.8874 24.5 32.7207 26C30.7207 23.5 31.2207 21 32.7207 14.5C33.5794 10.7788 31.4168 9.73764 29.2011 9.69069C27.6235 9.65726 26.4181 10.8903 25.6145 12.2483C22.0385 18.291 14.8795 21.4474 7.72065 21C-0.27935 20.5 -3.77935 12.5004 5.72065 4.5004Z" fill="black" />
          </svg>
        </span>
        <h3 className="mb-0 ms-2">SEEDS GATE</h3>
      </div>
      {details && (
      <div className="container-fluid mb-3" style={{ backgroundColor: 'white' }}>
        <div className="p-2 pt-3 pb-3">
          <div className="d-flex align-items-center gap-2 flex-wrap">
            <div>
              <TextField
                className="addrequisiton_field"
                label="Name of the organization"
                variant="outlined"
                sx={{ width: 550 }}
                fullWidth
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                required
              />
              <p className="text-danger" style={{ height: '5px' }}>{(formik.touched.name && formik.errors.name) ? formik.errors.name : '  '}</p>
            </div>
            <div>
              <TextField
                className="addrequisiton_field"
                label="Name of the founder"
                variant="outlined"
                sx={{ width: 550 }}
                fullWidth
                name="founder_name"
                value={formik.values.founder_name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                required
              />
              <p className="text-danger" style={{ height: '5px' }}>{(formik.touched.founder_name && formik.errors.founder_name) ? formik.errors.founder_name : '  '}</p>
            </div>

          </div>
          <div className="d-flex align-items-center gap-2 mt-3 flex-wrap">
            <div style={{ width: 380 }}>
              <TextField
                className="addrequisiton_field"
                label="Email"
                variant="outlined"
                sx={{ width: 380 }}
                value={details?.details?.email}
                disabled
              />
              <p className="text-danger" style={{ height: '5px' }}>{'  '}</p>
            </div>
            <div>
              <TextField
                className="addrequisiton_field"
                label="Ph. No."
                variant="outlined"
                sx={{ width: 380 }}
                name="phone_number"
                value={formik.values.phone_number}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                required
              />
              <p className="text-danger" style={{ height: '5px' }}>{(formik.touched.phone_number && formik.errors.phone_number) ? formik.errors.phone_number : '  '}</p>
            </div>
            <div>
              <Autocomplete
                className="data_field"
                disablePortal
                id="combo-box-demo"
                options={AllowedStartupRegion}
                sx={{ width: 325 }}
                name="region"
                value={formik.values.region}
                renderInput={(params) => <TextField {...params} label="Region" required />}
                onChange={(e, newValue) => {
                  formik.setFieldTouched('region', true, true);
                  formik.setFieldValue('region', newValue ?? '', true);
                }}
                onBlur={(e) => {
                  if (!formik.touched.region) {
                    formik.setFieldTouched('region', true, true);
                  }
                }}
              />
              <p className="text-danger" style={{ height: '5px' }}>{(formik.touched.region && formik.errors.region) ? formik.errors.region : '  '}</p>
            </div>

          </div>
          <div>
            <div className="form_details mt-3">
              <p className="mb-0">What Is Your Company Industry?</p>
            </div>
            <div className="mt-2">
              <FormGroup>
                {AllowedStartupModulesList.map((module, index) => (
                  <>
                    {module !== AllowedStartupIndustry.OTHERS
                    && (
                    <FormControlLabel
                      control={(
                        <Checkbox
                          className="radio_button"
                          onChange={(e, val) => handleUpdateIndustry(module, val)}
                        />
)}
                      label={module}
                    />
                    )}
                    {module === AllowedStartupIndustry.OTHERS
                    && (
                    <div className="d-flex align-items-center">
                      {' '}
                      <FormControlLabel
                        control={(
                          <Checkbox
                            className="radio_button"
                            onChange={(e, val) => handleUpdateIndustry(module, val)}
                          />
)}
                        label={`${AllowedStartupIndustry.OTHERS} [please specify]`}
                      />
                      <TextField
                        className="addrequisiton_field"
                        label="Type Industry name"
                        variant="outlined"
                        disabled={!Object.keys(formik.values.industry).includes(AllowedStartupIndustry.OTHERS)}
                        value={otherIndustry}
                        onChange={(e) => {
                          const data = formik.values.industry;
                          Object.keys(data).forEach((item) => {
                            if (item === AllowedStartupIndustry.OTHERS) {
                              data[item] = e.target.value;
                            }
                          });
                          formik.setFieldValue('industry', data);
                          setOtherIndustry(e.target.value);
                        }}
                        sx={{ width: 340 }}
                        error={Object.keys(formik.values.industry).includes(AllowedStartupIndustry.OTHERS) && (otherIndustry === '' || !otherIndustry)}
                      />
                      {Object.keys(formik.values.industry).includes(AllowedStartupIndustry.OTHERS) && (otherIndustry === '' || !otherIndustry) && (
                      <span className="text-danger ms-2">Please enter industry name</span>
                      )}
                    </div>
                    )}
                  </>
                ))}
              </FormGroup>
            </div>
          </div>
          <div>
            <div className="form_details mt-3">
              <p className="mb-0">Problems and Solutions *</p>
            </div>
            <div className="mt-2">
              <TextField
                sx={{ width: '100%' }}
                id="outlined-multiline-static"
                label="Describe the problem you solve"
                multiline
                rows={3}
                name="problem"
                value={formik.values.problem}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                required
              />
              {formik.touched.problem && formik.errors.problem && (
              <span className="text-danger">{formik.errors.problem}</span>
              )}
            </div>
          </div>
          <div>
            <div className="form_details mt-3">
              <p className="mb-0">The Team</p>
            </div>
            <div className="d-flex align-items-center gap-2">
              <div className="mt-2">
                <TextField
                  sx={{ width: 300 }}
                  id="outlined-multiline-static"
                  label="Number of Founders"
                  multiline
                  value={numberOfFounders}
                  onChange={(e) => setNumberOfFounders(e.target.value)}
                />
              </div>
              <div className="mt-2">
                <TextField
                  key={trigger}
                  sx={{ width: 770 }}
                  id="outlined-multiline-static"
                  label="Member Name"
                  required
                  value={memberName}
                  onChange={(e) => setMemberName(e.target.value)}
                  error={numberOfFounders && numberOfFounders !== JSON.stringify(formik.values.teams.length)}
                />
              </div>
              <div className="me-2">
                <ControlPointIcon
                  style={{ cursor: isDisable ? 'not-allowed' : 'pointer' }}
                  onClick={(e) => (isDisable ? e.preventDefault() : handleAddRow())}
                />
              </div>
              {numberOfFounders && numberOfFounders !== JSON.stringify(formik.values.teams.length) && (
                <span className="text-danger">
                  Please add
                  {' '}
                  {numberOfFounders - formik.values.teams.length}
                  {' '}
                  team members
                </span>
              )}
            </div>
            <p className="mt-1">(Please click the plus (+) button after entering the member name)</p>

            <div className="d-flex mt-2">
              {formik.values.teams?.length > 0
                && formik.values.teams.map((item, index) => (
                  <span
                    key={index}
                    className="c-pill c-pill--success d-flex changeRequest_badge ms-1"
                    style={{ backgroundColor: '#e6ffe6' }}
                  >
                    {item}
                    <ClearIcon
                      // className="ri-close-line ms-1"
                      // style={{ backgroundColor: '#33cc33', cursor: 'pointer' }}
                      onClick={(event) => {
                        event.stopPropagation();
                        handleDeleteRow(index);
                      }}
                    />
                  </span>
                ))}
            </div>
          </div>
          <div>
            <div className="form_details mt-3">
              <p className="mb-0">Product Description</p>
            </div>
            <div className="mt-2">
              <TextField
                sx={{ width: '100%' }}
                id="outlined-multiline-static"
                label="Write something about the product"
                multiline
                rows={3}
                name="description"
                value={formik.values.description}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                required
              />
              {formik.touched.description && formik.errors.description && (
              <span className="text-danger">{formik.errors.description}</span>
              )}
            </div>
          </div>
          <div>
            <div className="form_details mt-3">
              <p className="mb-0">Describe your company stage</p>
            </div>
            <div className="mt-2">
              <FormControl>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  name="stage"
                  defaultValue={formik.values.stage}
                  value={formik.values.stage}
                  onClick={formik.handleChange}
                >
                  <FormControlLabel key={AllowedStartupStage.PRE_PRODUCT} value={AllowedStartupStage.PRE_PRODUCT} control={<Radio className="radio_button" />} label={AllowedStartupStage.PRE_PRODUCT} />
                  <FormControlLabel key={AllowedStartupStage.MVP} value={AllowedStartupStage.MVP} control={<Radio className="radio_button" />} label={AllowedStartupStage.MVP} />
                  <FormControlLabel key={AllowedStartupStage.POST_PRODUCT} value={AllowedStartupStage.POST_PRODUCT} control={<Radio className="radio_button" />} label={AllowedStartupStage.POST_PRODUCT} />
                  <FormControlLabel key={AllowedStartupStage.GROWTH} value={AllowedStartupStage.GROWTH} control={<Radio className="radio_button" />} label={AllowedStartupStage.GROWTH} />
                </RadioGroup>
              </FormControl>

            </div>
          </div>
          <div>
            <div className="form_details mt-3">
              <p className="mb-0">How much money you are raising</p>
            </div>
            <div className="mt-2">
              <FormControl>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  name="raising_amount"
                  value={formik.values.raising_amount}
                  onClick={formik.handleChange}
                  className="radio_button"
                >
                  <FormControlLabel value={AllowedStartupRaisingAmount.FAMILY_FRIENDS} control={<Radio className="radio_button" />} label={AllowedStartupRaisingAmount.FAMILY_FRIENDS} />
                  <FormControlLabel value={AllowedStartupRaisingAmount.ANGEL_ROUND} control={<Radio className="radio_button" />} label={AllowedStartupRaisingAmount.ANGEL_ROUND} />
                  <FormControlLabel value={AllowedStartupRaisingAmount.SEEDS_ROUND} control={<Radio className="radio_button" />} label={AllowedStartupRaisingAmount.SEEDS_ROUND} />
                  <FormControlLabel value={AllowedStartupRaisingAmount.SERIES_A} control={<Radio className="radio_button" />} label={AllowedStartupRaisingAmount.SERIES_A} />
                </RadioGroup>
              </FormControl>
            </div>
          </div>
          <div>
            <div className="form_details mt-3">
              <p className="mb-0">Valuation</p>
            </div>
            <div className="mt-2">
              <TextField
                sx={{ width: '100%' }}
                type="number"
                id="outlined-multiline-static"
                label="We ask the startup to enter how much their sales for last year then we multiply it by 10, the result number will be their valuation"
                name="valuation"
                onWheel={(e) => e.target.blur()}
                onKeyDown={handleKeyDown}
                value={formik.values.valuation}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                required
              />
              {formik.touched.valuation && formik.errors.valuation && (
              <span className="text-danger">{formik.errors.valuation}</span>
              )}
            </div>
          </div>
          <div>
            <div className="form_details mt-3">
              <p className="mb-0">Please Fill The Following Tables if Applicable</p>
            </div>
            <div className="mt-3">
              <table className="module_table">
                <tr>
                  <th style={{ maxWidth: '250px', minWidth: '250px' }}>Module</th>
                  <th>Jan 24</th>
                  <th>Feb 24</th>
                  <th>March 24</th>
                  <th>April 24</th>
                  <th>May 24</th>
                  <th>Jun 24</th>
                  <th>Jul 24</th>
                  <th>Aug 24</th>
                  <th>Sep 24</th>
                  <th>Oct 24</th>
                  <th>Nov 24</th>
                  <th>Dec 24</th>
                </tr>
                {formik.values.modules?.length > 0
                && formik.values.modules.map((module, index) => (
                  <tr style={{ borderBottom: '1px solid #E3E3E3' }} key={index}>
                    <td>{module?.type}</td>
                    <td label className="table_input">
                      <input
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        onKeyDown={handleKeyDown}
                        name={`modules[${index}].jan`}
                        value={module?.jan}
                        onChange={formik.handleChange}
                      />
                    </td>
                    <td label className="table_input">
                      <input
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        onKeyDown={handleKeyDown}
                        name={`modules[${index}].feb`}
                        value={module?.feb}
                        onChange={formik.handleChange}
                      />
                    </td>
                    <td label className="table_input">
                      <input
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        onKeyDown={handleKeyDown}
                        name={`modules[${index}].mar`}
                        value={module?.mar}
                        onChange={formik.handleChange}
                      />
                    </td>
                    <td label className="table_input">
                      <input
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        onKeyDown={handleKeyDown}
                        name={`modules[${index}].apr`}
                        value={module?.apr}
                        onChange={formik.handleChange}
                      />
                    </td>
                    <td label className="table_input">
                      <input
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        onKeyDown={handleKeyDown}
                        name={`modules[${index}].may`}
                        value={module?.may}
                        onChange={formik.handleChange}
                      />
                    </td>
                    <td label className="table_input">
                      <input
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        onKeyDown={handleKeyDown}
                        name={`modules[${index}].jun`}
                        value={module?.ju}
                        onChange={formik.handleChange}
                      />
                    </td>
                    <td label className="table_input">
                      <input
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        onKeyDown={handleKeyDown}
                        name={`modules[${index}].jul`}
                        value={module?.jul}
                        onChange={formik.handleChange}
                      />
                    </td>
                    <td label className="table_input">
                      <input
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        onKeyDown={handleKeyDown}
                        name={`modules[${index}].aug`}
                        value={module?.aug}
                        onChange={formik.handleChange}
                      />
                    </td>
                    <td label className="table_input">
                      <input
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        onKeyDown={handleKeyDown}
                        name={`modules[${index}].sep`}
                        value={module?.sep}
                        onChange={formik.handleChange}
                      />
                    </td>
                    <td label className="table_input">
                      <input
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        onKeyDown={handleKeyDown}
                        name={`modules[${index}].oct`}
                        value={module?.oct}
                        onChange={formik.handleChange}
                      />
                    </td>
                    <td label className="table_input">
                      <input
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        onKeyDown={handleKeyDown}
                        name={`modules[${index}].nov`}
                        value={module?.nov}
                        onChange={formik.handleChange}
                      />
                    </td>
                    <td label className="table_input">
                      <input
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        onKeyDown={handleKeyDown}
                        name={`modules[${index}].dec`}
                        value={module?.dec}
                        onChange={formik.handleChange}
                      />
                    </td>
                  </tr>
                ))}
              </table>
            </div>
          </div>
          <div style={{ borderTop: '1px solid #CBD7EF' }} className="d-flex justify-content-center align-items-center mt-3">
            <button
              type="submit"
              className={`submit_button mt-3 ${formik.isValid ? '' : 'bg-secondary'}`}
              onClick={handleUpdate}
              disabled={startupUpdateLoading || !formik.isValid}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
      )}
    </div>
  );
}

export default StartupOnboarding;
