import {
  call, put, takeEvery, takeLatest,
} from 'redux-saga/effects';

// Login Redux States
import toast from 'react-hot-toast';
import { LOGIN_USER, LOGOUT_USER, SOCIAL_LOGIN, UPDATE_DETAILS } from './actionTypes';
import { apiError, loginSuccess, logoutUserSuccess } from './actions';

// Include Both Helper File with needed methods
import { getFirebaseBackend } from '../../../helpers/firebase_helper';
import {
  postFakeLogin,
  postJwtLogin,
  postSocialLogin,
} from '../../../helpers/fakebackend_helper';
import { init } from '../../../helpers/api_helper';
import { login } from '../../../api/auth';
import { LoginTypes } from '../../../constants/common';

const fireBaseBackend = getFirebaseBackend();

function* loginUser({ payload: { user, history } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === 'firebase') {
      const response = yield call(
        fireBaseBackend.loginUser,
        user.email,
        user.password,
      );
      yield put(loginSuccess(response));
    } else if (process.env.REACT_APP_DEFAULTAUTH === 'jwt') {
      const response = yield call(postJwtLogin, {
        email: user.email,
        password: user.password,
      });
      localStorage.setItem('authUser', JSON.stringify(response));
      yield put(loginSuccess(response));
    } else if (process.env.REACT_APP_DEFAULTAUTH === 'fake') {
      const response = yield call(postFakeLogin, {
        email: user.email,
        password: user.password,
      });
      localStorage.setItem('authUser', JSON.stringify(response));
      yield put(loginSuccess(response));
    } else if (process.env.REACT_APP_API_URL) {
      const response = yield call(login, {
        ...user,
      });
      if (response.statusCode === 200) {
        localStorage.setItem('authUser', JSON.stringify({ token: response.data.token, login_type: response.data.login_type }));
        console.log(response, 'res');
        yield put(loginSuccess(response.data));
        toast.success(response.message);
        init();
        if (response.data.login_type === LoginTypes.STARTUP) {
          history('/startup-dashboard');
        } else if (response.data.login_type === LoginTypes.INVESTOR) {
          history('/investor-dashboard');
        }
      }
      // else {
      //   toast.error(response.data.message);
      //   yield put(apiError(response));
      // }
    }
  } catch (error) {
    console.log(error, 'saga error');
    toast.error(error.message);
    yield put(apiError(error));
  }
}

function* updateDetails({ payload: { details } }) {
  try {
    yield put(loginSuccess(details));
  } catch (error) {
    console.log(error, 'update error');
  }
}

function* logoutUser({ payload: { history, details } }) {
  try {
    localStorage.removeItem('authUser');
    if (process.env.REACT_APP_DEFAULTAUTH === 'firebase') {
      const response = yield call(fireBaseBackend.logout);
      yield put(logoutUserSuccess(response));
    }
    if (details.login_type === LoginTypes.STARTUP) {
      history('/startup-login');
    } else if (details.login_type === LoginTypes.INVESTOR) {
      console.log(details, LoginTypes.INVESTOR, details.login_type === LoginTypes.INVESTOR, 'logout details');
      history('/investor-login');
    }
  } catch (error) {
    yield put(apiError(error));
  }
}

function* socialLogin({ payload: { data, history, type } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === 'firebase') {
      const fireBaseBackend = getFirebaseBackend();
      const response = yield call(
        fireBaseBackend.socialLoginUser,
        data,
        type,
      );
      localStorage.setItem('authUser', JSON.stringify(response));
      yield put(loginSuccess(response));
    } else {
      const response = yield call(postSocialLogin, data);

      localStorage.setItem('authUser', JSON.stringify(response));
      yield put(loginSuccess(response));
    }
    history('/dashboard');
  } catch (error) {
    yield put(apiError(error));
  }
}

function* authSaga() {
  yield takeEvery(UPDATE_DETAILS, updateDetails);
  yield takeEvery(LOGIN_USER, loginUser);
  yield takeLatest(SOCIAL_LOGIN, socialLogin);
  yield takeEvery(LOGOUT_USER, logoutUser);
}

export default authSaga;
