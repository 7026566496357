import { InvestorStartupRequestStatus } from '../constants/startup';

const StartupRequestStatus = (startupRequest) => {
  switch (startupRequest?.status) {
    case InvestorStartupRequestStatus.PENDING:
      return 'Request Pending';
    case InvestorStartupRequestStatus.APPROVED:
      return 'Access Dataroom';
    default:
      return 'Request Dataroom';
  }
};

const StatusColor = (startupRequest, type) => {
  switch (startupRequest?.status) {
    case InvestorStartupRequestStatus.PENDING:
      return type ? '#f3db29' : 'pending_request_color';
    case InvestorStartupRequestStatus.APPROVED:
      return type ? '#05AE53' : 'approved_request_color';
    default:
      return type ? '#7b99f3' : 'default_request_color';
  }
};

export {
  StartupRequestStatus,
  StatusColor,
};
