export const LoginTypes = {
  STARTUP: 'Startup',
  INVESTOR: 'Investor',
};

export const InvestorRequestStatus = {
  PENDING: 'Pending',
  REJECTED: 'Rejected',
  APPROVED: 'Approved',
};
