import React from 'react';
import PersonIcon from '@mui/icons-material/Person';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { LoginTypes } from '../../constants/common';
import { loginUser } from '../../store/actions';
// import {
//   Card, CardBody, CardTitle, CardText, Button,
// } from 'reactstrap';
// import TuneIcon from '@mui/icons-material/Tune';

function InvestorLogin() {
  const dispatch = useDispatch();
  const history = useNavigate();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [showPassword, setShowPassword] = React.useState(false);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: '',
      password: '',
      login_type: LoginTypes.INVESTOR,
    },
    validationSchema: Yup.object({
      email: Yup.string().required('Please Enter Your Email'),
      password: Yup.string().required('Please Enter Your Password'),
    }),
    // onSubmit: (values) => {
    //   dispatch(loginUser(values, props.router.navigate));
    //   // navigate('/dashboard');
    // },
  });

  const attemptLogin = () => {
    dispatch(loginUser({ ...validation.values }, history));
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return (
    <div className="login_wrap bg-white">
      <div className="login_details bg-white">
        <div className="ssts_details">
          <h2>Seeds Gate</h2>
          <h4>
            You Are One Step Away From Accessign
            <span className="ssts_text">STTS</span>
            {' '}
            Platform
          </h4>
          <p>
            Utilize AI technology in your investment strategy. Reduce risk and achieve better ROI with
            <span className="ssts_text_bold"> STTS</span>
            {' '}
            platform:
          </p>
        </div>
        <div className="listing_groups">
          <div className="listing_holder">
            <span>
              {' '}
              <svg width="18" height="19" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M10 0.5C4.5 0.5 0 5 0 10.5C0 16 4.5 20.5 10 20.5C15.5 20.5 20 16 20 10.5C20 5 15.5 0.5 10 0.5ZM8 15.5L3 10.5L4.41 9.09L8 12.67L15.59 5.08L17 6.5L8 15.5Z"
                  fill="#296CED"
                />
              </svg>
            </span>
            <span className="ms-1">Sourcing & Recommendations</span>
          </div>
          <div className="listing_holder mt-2">
            <span>
              {' '}
              <svg width="18" height="19" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M10 0.5C4.5 0.5 0 5 0 10.5C0 16 4.5 20.5 10 20.5C15.5 20.5 20 16 20 10.5C20 5 15.5 0.5 10 0.5ZM8 15.5L3 10.5L4.41 9.09L8 12.67L15.59 5.08L17 6.5L8 15.5Z"
                  fill="#296CED"
                />
              </svg>
            </span>
            <span className="ms-1">AI Screeing & Survivability Index</span>
          </div>
          <div className="listing_holder mt-2">
            <span>
              {' '}
              <svg width="18" height="19" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M10 0.5C4.5 0.5 0 5 0 10.5C0 16 4.5 20.5 10 20.5C15.5 20.5 20 16 20 10.5C20 5 15.5 0.5 10 0.5ZM8 15.5L3 10.5L4.41 9.09L8 12.67L15.59 5.08L17 6.5L8 15.5Z"
                  fill="#296CED"
                />
              </svg>
            </span>
            <span className="ms-1">Portfolio Management & Reporting</span>
          </div>
          <div className="listing_holder mt-2">
            <span>
              {' '}
              <svg width="18" height="19" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M10 0.5C4.5 0.5 0 5 0 10.5C0 16 4.5 20.5 10 20.5C15.5 20.5 20 16 20 10.5C20 5 15.5 0.5 10 0.5ZM8 15.5L3 10.5L4.41 9.09L8 12.67L15.59 5.08L17 6.5L8 15.5Z"
                  fill="#296CED"
                />
              </svg>
            </span>
            <span className="ms-1">On-Demand & Live Pitching</span>
          </div>
        </div>
      </div>
      <div className="login_details login_background">
        <div className="investor_login_card">
          <div className="welcom_back_text">
            <h2>Welcome Back!</h2>
            <p>Let's Login To Proceed</p>
          </div>
          <div>
            <div className="tab_content">
              <div>
                <div>
                  <FormControl sx={{ width: '100%' }} variant="outlined">
                    <InputLabel htmlFor="login-email">Email</InputLabel>
                    <OutlinedInput
                      id="login-email"
                      type="text"
                      name="email"
                      value={validation.values.email}
                      onChange={validation.handleChange}
                    //   value={validation.values.email}
                    //   onChange={validation.handleChange}
                      startAdornment={(
                        <InputAdornment position="start">
                          <IconButton
                            className="inputfiled_icon"
                            edge="start"
                          >
                            <PersonIcon />
                          </IconButton>
                        </InputAdornment>
                              )}
                      label="Email"
                    />
                  </FormControl>
                </div>
                <div className="mt-3">
                  <FormControl sx={{ width: '100%' }} variant="outlined">
                    <InputLabel htmlFor="login-password">Password</InputLabel>
                    <OutlinedInput
                      id="login-password"
                      name="password"
                      type={showPassword ? 'text' : 'password'}
                      value={validation.values.password}
                      onChange={validation.handleChange}
                      startAdornment={(
                        <InputAdornment position="start">
                          <IconButton
                            className="inputfiled_icon"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="start"
                          >
                            {
                                      !showPassword ? <RemoveRedEyeIcon /> : <VisibilityOffIcon />
                            }
                          </IconButton>
                        </InputAdornment>
                              )}
                      label="Password"
                    />
                  </FormControl>
                </div>
                <button className="login_button mt-3" onClick={() => { attemptLogin(); }}>
                  LOG IN
                </button>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
}

export default InvestorLogin;
