import React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import {
  Checkbox, FormControlLabel, FormGroup, TextField,
} from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import LockIcon from '@mui/icons-material/Lock';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { loginUser } from '../../store/actions';
import { LoginTypes } from '../../constants/common';

function CustomTabPanel(props) {
  const {
    children, value, index, ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
function SeedsGateLogin() {
  const [value, setValue] = React.useState(0);
  const dispatch = useDispatch();
  const history = useNavigate();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [showPassword, setShowPassword] = React.useState(false);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: '',
      password: '',
      login_type: LoginTypes.STARTUP,
    },
    validationSchema: Yup.object({
      email: Yup.string().required('Please Enter Your Email'),
      password: Yup.string().required('Please Enter Your Password'),
    }),
    // onSubmit: (values) => {
    //   dispatch(loginUser(values, props.router.navigate));
    //   // navigate('/dashboard');
    // },
  });

  const attemptLogin = () => {
    dispatch(loginUser({ ...validation.values }, history));
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return (
    <div>
      <div>
        <div className="login_wrap login_background">
          <div className="login_details">
            <div className="startup_details">
              <h2>Seeds Gate</h2>
              <p>
                Lorem ipsum dolor sit amet, consectetur.
                {' '}
                <br />
                Lorem ipsum dolor sit amet, consectetur adipiscing eli.
              </p>
            </div>
          </div>
          <div className="login_details">
            <div className="login_card">
              <div className="welcom_back_text">
                <h2>Welcome Back!</h2>
                <p> Let's Login To Proceed</p>
              </div>
              <div>
                <div className="tab_content">
                <div>
                        <div>
                          <FormControl sx={{ width: '100%' }} variant="outlined">
                            <InputLabel htmlFor="login-email">Email</InputLabel>
                            <OutlinedInput
                              id="login-email"
                              type="text"
                              name="email"
                              value={validation.values.email}
                              onChange={validation.handleChange}
                              startAdornment={(
                                <InputAdornment position="start">
                                  <IconButton
                                    className="inputfiled_icon"
                                    edge="start"
                                  >
                                    <PersonIcon />
                                  </IconButton>
                                </InputAdornment>
                              )}
                              label="Email"
                            />
                          </FormControl>
                        </div>
                        <div className="mt-2">
                          <FormControl sx={{ width: '100%' }} variant="outlined">
                            <InputLabel htmlFor="login-password">Password</InputLabel>
                            <OutlinedInput
                              id="login-password"
                              name="password"
                              type={showPassword ? 'text' : 'password'}
                              value={validation.values.password}
                              onChange={validation.handleChange}
                              startAdornment={(
                                <InputAdornment position="start">
                                  <IconButton
                                    className="inputfiled_icon"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="start"
                                  >
                                    {
                                      !showPassword ? <RemoveRedEyeIcon /> : <VisibilityOffIcon />
                                    }
                                  </IconButton>
                                </InputAdornment>
                              )}
                              label="Password"
                            />
                          </FormControl>
                        </div>
                        <button className="login_button mt-3" onClick={() => { attemptLogin(); }}>
                          LOG IN
                        </button>
                      </div>
                  {/* <Box sx={{ width: '100%' }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                      <Tabs className="login_tabs" value={value} onChange={handleChange} aria-label="basic tabs example" style={{ padding: '0px', justifyContent: 'space-between' }}>
                        <Tab label="LOGIN" {...a11yProps(0)} />
                        <Tab label="SIGN UP" {...a11yProps(1)} />
                      </Tabs>
                    </Box>
                    <CustomTabPanel className="login_inputfield" value={value} index={0} style={{ padding: '0' }}>
                      <div>
                        <div>
                          <FormControl sx={{ m: 1, width: '100%' }} variant="outlined">
                            <InputLabel htmlFor="login-email">Email</InputLabel>
                            <OutlinedInput
                              id="login-email"
                              type="text"
                              name="email"
                              value={validation.values.email}
                              onChange={validation.handleChange}
                              startAdornment={(
                                <InputAdornment position="start">
                                  <IconButton
                                    className="inputfiled_icon"
                                    edge="start"
                                  >
                                    <PersonIcon />
                                  </IconButton>
                                </InputAdornment>
                              )}
                              label="Email"
                            />
                          </FormControl>
                        </div>
                        <div className="mt-2">
                          <FormControl sx={{ m: 1, width: '100%' }} variant="outlined">
                            <InputLabel htmlFor="login-password">Password</InputLabel>
                            <OutlinedInput
                              id="login-password"
                              name="password"
                              type={showPassword ? 'text' : 'password'}
                              value={validation.values.password}
                              onChange={validation.handleChange}
                              startAdornment={(
                                <InputAdornment position="start">
                                  <IconButton
                                    className="inputfiled_icon"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="start"
                                  >
                                    {
                                      !showPassword ? <RemoveRedEyeIcon /> : <VisibilityOffIcon />
                                    }
                                  </IconButton>
                                </InputAdornment>
                              )}
                              label="Password"
                            />
                          </FormControl>
                        </div>
                        <button className="login_button mt-3" onClick={() => { attemptLogin(); }}>
                          LOG IN
                        </button>
                      </div>
                    </CustomTabPanel>
                    <CustomTabPanel className="login_inputfield" value={value} index={1}>
                      <div className="investor_scroll">
                        <div style={{ marginRight: '10px' }}>
                          <FormControl sx={{ m: 1, width: '100%' }} variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-password">Startup Name</InputLabel>
                            <OutlinedInput
                              id="outlined-adornment-password"
                              type={showPassword ? 'text' : 'password'}
                              startAdornment={(
                                <InputAdornment position="start">
                                  <IconButton
                                    className="inputfiled_icon"
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="start"
                                  >
                                    <PersonIcon />
                                  </IconButton>
                                </InputAdornment>
                              )}
                              label="Startup Name"
                            />
                          </FormControl>
                        </div>
                        <div className="mt-2" style={{ marginRight: '10px' }}>
                          <FormControl sx={{ m: 1, width: '100%' }} variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-password">Phone</InputLabel>
                            <OutlinedInput
                              id="outlined-adornment-password"
                              type={showPassword ? 'text' : 'password'}
                              startAdornment={(
                                <InputAdornment position="start">
                                  <IconButton
                                    className="inputfiled_icon"
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="start"
                                  >
                                    <LocalPhoneIcon />
                                  </IconButton>
                                </InputAdornment>
                              )}
                              label="Phone"
                            />
                          </FormControl>
                        </div>
                        <div className="mt-2" style={{ marginRight: '10px' }}>
                          <FormControl sx={{ m: 1, width: '100%' }} variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-password">Email</InputLabel>
                            <OutlinedInput
                              id="outlined-adornment-password"
                              type={showPassword ? 'text' : 'password'}
                              startAdornment={(
                                <InputAdornment position="start">
                                  <IconButton
                                    className="inputfiled_icon"
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="start"
                                  >
                                    <EmailIcon />
                                  </IconButton>
                                </InputAdornment>
                              )}
                              label="Email"
                            />
                          </FormControl>
                        </div>
                        <div className="mt-2" style={{ marginRight: '10px' }}>
                          <FormControl sx={{ m: 1, width: '100%' }} variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                            <OutlinedInput
                              id="outlined-adornment-password"
                              type={showPassword ? 'text' : 'password'}
                              startAdornment={(
                                <InputAdornment position="start">
                                  <IconButton
                                    className="inputfiled_icon"
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="start"
                                  >
                                    <LockIcon />
                                  </IconButton>
                                </InputAdornment>
                              )}
                              label="Password"
                            />
                          </FormControl>
                        </div>
                        <div className="mt-2" style={{ marginRight: '10px' }}>
                          <FormControl sx={{ m: 1, width: '100%' }} variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-password">Re-type-Password</InputLabel>
                            <OutlinedInput
                              id="outlined-adornment-password"
                              type={showPassword ? 'text' : 'password'}
                              startAdornment={(
                                <InputAdornment position="start">
                                  <IconButton
                                    className="inputfiled_icon"
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="start"
                                  >
                                    <LockIcon />
                                  </IconButton>
                                </InputAdornment>
                              )}
                              label="Re-type-Password"
                            />
                          </FormControl>
                        </div>
                      </div>
                      <div>
                      <button className="login_button mt-3" onClick="redirectToPage()">
                          SIGN UP
                        </button>
                      </div>
                    </CustomTabPanel>
                  </Box> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SeedsGateLogin;
