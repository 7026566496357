import React from 'react';
import { useSelector } from 'react-redux';
// import {
//   Card, CardBody, CardTitle, CardText, Button,
// } from 'reactstrap';

function InvestorDashboardDisplay() {
  const { details } = useSelector((state) => ({
    details: state.Login.details,
  }));

  document.title = 'Investor Dashboard';

  return (
    <div className="page-content">
      <div className="container-fluid mb-3" style={{ backgroundColor: 'white' }}>
        <div className="p-2 pt-3 pb-3">
          <div className="dashboard_border_box mb-2">
            <div>
              <p className="mb-2 organization_text">Investor Information</p>
            </div>
            <div className=" organization-details p-2">
              <div className="border_right">
                <p className="mb-0 organization-details_text">Name</p>
                <p className="mb-0 organization-profile_details">{details?.details?.name ?? 'N/A'}</p>
              </div>
              <div className="border_right">
                <p className="mb-0 organization-details_text">Email</p>
                <p className="mb-0 organization-profile_details">{details?.details?.email ?? 'N/A'}</p>
              </div>
              <div>
                <p className="mb-0 organization-details_text">Phone</p>
                <p className="mb-0 organization-profile_details">{details?.details?.phone_number ?? 'N/A'}</p>
              </div>
            </div>
            <div className="organization-details p-2" style={{ borderTop: '1px solid #CCCCCC' }}>
              <div className="border_right">
                <p className="mb-0 organization-details_text">Fund Size</p>
                <p className="mb-0 organization-profile_details">{details?.details?.fundSize ?? 'N/A'}</p>
              </div>
              <div className="border_right">
                <p className="mb-0 organization-details_text">Region</p>
                <p className="mb-0 organization-profile_details">{details?.details?.region ?? 'N/A'}</p>
              </div>
              <div>
                <p className="mb-0 organization-details_text">Stage</p>
                <p className="mb-0 organization-profile_details">{details?.details?.stage ?? 'N/A'}</p>
              </div>
            </div>
            <div className="organization-details p-2" style={{ borderTop: '1px solid #CCCCCC' }}>
              <div className="border_right">
                <p className="mb-0 organization-details_text">Ticket Size</p>
                <p className="mb-0 organization-profile_details">{details?.details?.ticket_size_range ?? 'N/A'}</p>
              </div>
              <div>
                <p className="mb-0 organization-details_text">Area of Investment</p>
                <p className="mb-0 organization-profile_details">{details?.details?.area_of_investment ?? 'N/A'}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InvestorDashboardDisplay;
