import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import withRouter from '../../components/Common/withRouter';

import { logoutUser, updateDetails } from '../../store/actions';

function Logout() {
  document.title = 'Seeds Gate';

  const history = useNavigate();
  const dispatch = useDispatch();
  const { details } = useSelector((state) => ({
    details: state.Login.details,
  }));

  useEffect(() => {
    // dispatch(updateDetails(undefined));
    dispatch(logoutUser(history, {
      login_type: details.login_type,
    }));
    // dispatch(logoutUserSuccess(history));
  }, [dispatch, history]);

  return <></>;
}

Logout.propTypes = {
  history: PropTypes.object,
  logoutUser: PropTypes.func,
};

export default withRouter(connect(null, { logoutUser })(Logout));
