import { useState } from 'react';
import { CardBody, Card } from 'reactstrap';
import { Autocomplete, TextField } from '@mui/material';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { styled } from '@mui/material/styles';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});
function FundRaisingKit() {
  const fundraisingkit = [
    { label: 'The Shawshank Redemption', year: 1994 },
    { label: 'The Godfather', year: 1972 },
    { label: 'The Godfather: Part II', year: 1974 },
    { label: 'The Dark Knight', year: 2008 },
    { label: '12 Angry Men', year: 1957 },
    { label: "Schindler's List", year: 1993 },
    { label: 'Pulp Fiction', year: 1994 },
    {
      label: 'The Lord of the Rings: The Return of the King',
      year: 2003,
    },
  ];
  return (
    <div className="page-content">
      <div className="container-fluid mb-3">
        <div className="investor_list">
          <h2 className="mb-3">Fundraising Kit</h2>
        </div>
        <Card>
          <CardBody>
            <div className="dashboard_outer_div">
              <div className="row">
                <div className="col-md-6">
                  <div>
                    <TextField
                      className="addrequisiton_field"
                // id={`value-${index}`}
                      label="Name of the organization"
                      name="first_name"
                      required
                //   onChange={investorForm.handleChange}
                //   onBlur={investorForm.handleBlur}
                //   value={investorForm.values.first_name}
                      variant="outlined"
                      fullWidth
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div>
                    <TextField
                      className="addrequisiton_field"
                // id={`value-${index}`}
                      label="Name of the founder"
                      name="first_name"
                      required
                //   onChange={investorForm.handleChange}
                //   onBlur={investorForm.handleBlur}
                //   value={investorForm.values.first_name}
                      variant="outlined"
                      fullWidth
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4 mt-2">
                  <div>
                    {/* <Autocomplete
                      id="region"
                      name="Where are you headequatered ?"
                      className="data_field"
                      options={fundraisingkit}
                    /> */}
                    <Autocomplete
                      id="region"
                      name="What Is your Industry sector ?"
                      className="data_field"
                      options={fundraisingkit}
                // value={investorForm.values.region}
                      renderInput={(params) => <TextField {...params} label="Region" required />}
                    />
                  </div>
                </div>
                <div className="col-md-4 mt-2">
                  <div>
                    <TextField
                      className="addrequisiton_field"
                      label="No of Employess"
                      name="first_name"
                      required
                      variant="outlined"
                      fullWidth
                    />
                  </div>
                </div>
                <div className="col-md-4 mt-2">
                  <div>
                    {/* <Autocomplete
                      id="region"
                      name="What Is your Industry sector ?"
                      className="data_field"
                      options={fundraisingkit}
                    /> */}
                    <Autocomplete
                      id="region"
                      name="What Is your Industry sector ?"
                      className="data_field"
                      options={fundraisingkit}
                // value={investorForm.values.region}
                      renderInput={(params) => <TextField {...params} label="Region" required />}
                    />
                  </div>
                </div>
              </div>
              <div className=" mt-2 mb-2">
                <Button
                className='custom-file-upload upload_button'
                  component="label"
                  role={undefined}
                  variant="contained"
                  tabIndex={-1}
                  startIcon={<CloudUploadIcon />}
                >
                  Upload file
                  <VisuallyHiddenInput type="file" />
                </Button>
              </div>
              <div style={{ borderTop: '1px solid #CBD7EF' }} className="d-flex justify-content-center align-items-center mt-3">
                <button
                  className="submit_button mt-3"
                >
                  Submit
                </button>
              </div>
            </div>
          </CardBody>
        </Card>
      </div>
    </div>
  );
}

export default FundRaisingKit;
