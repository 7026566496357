import { APIClient } from '../helpers/api_helper';

const AxiosClient = new APIClient();

const login = (payload) => AxiosClient.post('/login', payload);
const me = () => AxiosClient.get('/me');
const resetData = () => AxiosClient.get('/reset-data');

export {
  login,
  me,
  resetData,
};
