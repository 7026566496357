import React, { useEffect } from 'react';
// Redux
import { Link } from 'react-router-dom';

import {
  Row, Col, CardBody, Card, Container, Form, Input, Label, FormFeedback,
} from 'reactstrap';

// Formik Validation
import * as Yup from 'yup';
import { useFormik } from 'formik';

// import images
import logo from '../../assets/images/logo-dark.png';
import logolight from '../../assets/images/logo-light.png';

function Login() {
  document.title = ' Login | Minible - Responsive Bootstrap 5 Admin Dashboard';
  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      username: '',
      password: '',
    },
    validationSchema: Yup.object({
      username: Yup.string().required('Please Enter Your UserName'),
      password: Yup.string().required('Please Enter Your Password'),
    }),
    onSubmit: (values) => {
      console.log('values', values);
    },
  });

  useEffect(() => {
    document.body.className = 'authentication-bg';
    // remove classname when component will unmount
    return function cleanup() {
      document.body.className = '';
    };
  });

  return (
    <div className="account-pages my-5 pt-sm-5">
      <Container>
        <Row>
          <Col lg={12}>
            <div className="text-center">
              <Link to="/" className="mb-5 d-block auth-logo">
                <img src={logo} alt="" height="22" className="logo logo-dark" />
                <img src={logolight} alt="" height="22" className="logo logo-light" />
              </Link>
            </div>
          </Col>
        </Row>
        <Row className="align-items-center justify-content-center">
          <Col md={8} lg={6} xl={5}>
            <Card>

              <CardBody className="p-4">
                <div className="text-center mt-2">
                  <h5 className="text-primary">Welcome Back !</h5>
                  <p className="text-muted">Sign in to continue to Minible.</p>
                </div>
                <div className="p-2 mt-4">
                  <Form
                    className="form-horizontal"
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}
                  >
                    <div className="mb-3">
                      <Label className="form-label">Username</Label>
                      <Input
                        name="username"
                        className="form-control"
                        placeholder="Enter username"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.username || ''}
                        invalid={
                            !!(validation.touched.username && validation.errors.username)
                          }
                      />
                      {validation.touched.username && validation.errors.username ? (
                        <FormFeedback type="invalid">{validation.errors.username}</FormFeedback>
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <div className="float-end">
                        <Link to="/page-recoverpw" className="text-muted">Forgot password?</Link>
                      </div>
                      <Label className="form-label">Password</Label>
                      <Input
                        name="password"
                        type="password"
                        placeholder="Enter Password"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.password || ''}
                        invalid={
                            !!(validation.touched.password && validation.errors.password)
                          }
                      />
                      {validation.touched.password && validation.errors.password ? (
                        <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                      ) : null}
                    </div>

                    <div className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="customControlInline"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="customControlInline"
                      >
                        Remember me
                      </label>
                    </div>

                    <div className="mt-3 text-end">
                      <button
                        className="btn btn-primary w-sm waves-effect waves-light"
                        type="submit"
                      >
                        Log In
                      </button>
                    </div>

                    <div className="mt-4 text-center">
                      <h5 className="font-size-14 mb-3">Sign in with</h5>

                      <ul className="list-inline">
                        <li className="list-inline-item">
                          <Link
                            to="#"
                            className="social-list-item bg-primary text-white border-primary"
                          >
                            <i className="mdi mdi-facebook" />
                          </Link>
                        </li>
                        {' '}
                        <li className="list-inline-item">
                          <Link
                            to="#"
                            className="social-list-item bg-info text-white border-info"
                          >
                            <i className="mdi mdi-twitter" />
                          </Link>
                        </li>
                        {' '}
                        <li className="list-inline-item">
                          <Link
                            to="#"
                            className="social-list-item bg-danger text-white border-danger"
                          >
                            <i className="mdi mdi-google" />
                          </Link>
                        </li>
                      </ul>
                    </div>

                    <div className="mt-4 text-center">
                      <p className="mb-0">
                        Don&apos;t have an account ?
                        <a href="/pages-register" className="fw-medium text-primary"> Signup now </a>
                      </p>
                    </div>
                  </Form>
                </div>

              </CardBody>
            </Card>

            <div className="mt-5 text-center">
              <p>
                ©
                {' '}
                {new Date().getFullYear()}
                {' '}
                Minible. Crafted with
                {' '}
                <i className="mdi mdi-heart text-danger" />
                {' '}
                by Themesbrand
              </p>
            </div>

          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Login;
