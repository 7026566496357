import React from 'react';
import { useSelector } from 'react-redux';
import SeedsGateDashboard from './StartUpDashboard';
import StartupOnboarding from './StartUpOnboarding/SatrtupOnboardingForm';

function StartupDashboard() {
  const { details } = useSelector((state) => ({
    details: state.Login?.details,
  }));

  return (
    <>
      {
        details?.details?.profile_completed ? (
          <SeedsGateDashboard />
        ) : (
          <StartupOnboarding />
        )
    }
    </>
  );
}

export default StartupDashboard;
