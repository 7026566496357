import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import Avatar from '@mui/material/Avatar';

// i18n
import { withTranslation } from 'react-i18next';
// Redux
import { connect, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import withRouter from '../../Common/withRouter';

// users
// import user4 from '../../../assets/images/users/avatar-4.jpg';
import { MemberIcon } from '../../../helpers/member_icon';

function ProfileMenu(props) {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);
  const { details } = useSelector((state) => ({
    details: state.Login.details,
  }));

  // const [username, setusername] = useState('Admin');

  useEffect(() => {
    if (localStorage.getItem('authUser')) {
      // if (process.env.REACT_APP_DEFAULTAUTH === 'firebase') {
      //   const obj = JSON.parse(localStorage.getItem('authUser'));
      //   setusername(obj.displayName);
      // } else if (
      //   process.env.REACT_APP_DEFAULTAUTH === 'fake'
      //   || process.env.REACT_APP_DEFAULTAUTH === 'jwt'
      // ) {
      //   const obj = JSON.parse(localStorage.getItem('authUser'));
      //   if (obj.username) {
      //     setusername(obj.username);
      //   } else {
      //     setusername(obj.name);
      //   }
      // }

    }
  }, [props.success]);

  return (
    <Dropdown
      isOpen={menu}
      toggle={() => setMenu(!menu)}
      className="d-inline-block"
    >
      <DropdownToggle
        className="btn header-item waves-effect d-flex justify-content-between"
        id="page-header-user-dropdown"
        tag="button"
      >
        {
          details?.details?.name ? (
            <Avatar
              {...MemberIcon(details?.details?.name ?? 'Unkown User')}
              className="align-self-center"
            />
          )
            : (
              // <img
              //   className="rounded-circle header-profile-user align-self-center"
              //   src={user4}
              //   alt="Header Avatar"
              // />
              <AccountCircleIcon 
                className="align-self-center"
                sx={{
                  fontSize: '30px',
                }}
              />
            )
        }

        <span className="d-none d-xl-inline-block ms-1 fw-medium font-size-15 align-self-center">{details?.details?.name ?? details?.details?.email}</span>
        {' '}
        <i className="uil-angle-down d-none d-xl-inline-block font-size-15 align-self-center" />
      </DropdownToggle>
      <DropdownMenu className="dropdown-menu-end">
        {/* <DropdownItem tag="a" href="/profile">
          {' '}
          <i className="uil uil-user-circle font-size-18 align-middle text-muted me-1" />
          {props.t('View Profile')}
          {' '}
        </DropdownItem> */}
        {/* <DropdownItem tag="a" href="/">
          <i className="uil uil-wallet font-size-18 align-middle me-1 text-muted" />
          {props.t('My Wallet')}
        </DropdownItem>
        <DropdownItem tag="a" href="#">
          <i className="uil uil-cog font-size-18 align-middle me-1 text-muted" />
          {props.t('Settings')}
          <span className="badge bg-soft-success rounded-pill mt-1 ms-2">03</span>
        </DropdownItem>
        <DropdownItem tag="a" href="auth-lock-screen">
          <i className="uil uil-lock-alt font-size-18 align-middle me-1 text-muted" />
          {props.t('Lock screen')}
        </DropdownItem> */}
        <div className="dropdown-divider" />
        <Link to="/logout" className="dropdown-item">
          <i className="uil uil-sign-out-alt font-size-18 align-middle me-1 text-muted" />
          <span>{props.t('Logout')}</span>
        </Link>
      </DropdownMenu>
    </Dropdown>
  );
}

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
};

const mapStatetoProps = (state) => {
  const { error, success } = state.Profile;
  return { error, success };
};

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu)),
);
