import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import _ from 'lodash';
import { CircularProgress } from '@mui/material';
import { AllowedStartupModules } from '../../../constants/startup';
import { getStartupDetails, getStartupModule } from '../../../api/startup';

function SeedsGateDashboard() {
  const options = {
    chart: {
      type: 'bar',
      width: 200,
      height: 300,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
        endingShape: 'rounded',
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    xaxis: {
      categories: ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'],
    },
    yaxis: {
      title: {
        text: '',
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter(val) {
          return `${val}`;
        },
      },
    },
  };

  const [startupDeatils, setStartupDeatisl] = useState();
  const [industry, setIndustry] = useState();
  const [visitors, setVisitors] = useState();
  const [MMR, setMMR] = useState();
  const [burnRate, setBurnRate] = useState();
  const [cac, setCac] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const { details } = useSelector((state) => ({
    details: state.Login.details,
  }));
  document.title = 'Startup Dashboard';

  const fetchStartup = async () => {
    try {
      setIsLoading(true);
      const response = await getStartupDetails(details?.details?.id);
      if (response.statusCode === 200) {
        setStartupDeatisl(response.data.data);
        setIndustry(Object.values(response.data.data?.industry).join(', '));
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      toast.error(error.message);
    } finally {
    }
  };

  const fetchVisitorDetails = async () => {
    try {
      const response = await getStartupModule({
        startup_id: details?.details?.id,
        type: AllowedStartupModules.VISITORS,
        year: (new Date()).getFullYear(),
      });
      if (response.statusCode === 200) {
        const data = _.omit(response.data.data.data[0], 'id', 'created_at', 'updated_at', 'deleted_at', 'startup_id', 'year', 'type');
        const visitorsData = Object.values(data).map((v) => {
          if (v !== null) {
            return v;
          }
          return null;
        });
        setVisitors({
          series: [
            {
              name: 'Visitors',
              data: visitorsData,
            },
          ],
          options,
        });
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      toast.error(error.message);
    } finally {
    }
  };

  const fetchMMRDetails = async () => {
    try {
      const response = await getStartupModule({
        startup_id: details?.details?.id,
        type: AllowedStartupModules.MMR,
        year: (new Date()).getFullYear(),
      });
      if (response.statusCode === 200) {
        const data = _.omit(response.data.data.data[0], 'id', 'created_at', 'updated_at', 'deleted_at', 'startup_id', 'year', 'type');
        const mmrData = Object.values(data).map((v) => {
          if (v !== null) {
            return v;
          }
          return null;
        });
        setMMR({
          series: [
            {
              name: 'MMR',
              data: mmrData,
            },
          ],
          options,
        });
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      toast.error(error.message);
    } finally {
    }
  };

  const fetchBurnRateDetails = async () => {
    try {
      const response = await getStartupModule({
        startup_id: details?.details?.id,
        type: AllowedStartupModules.BURN_RATE,
        year: (new Date()).getFullYear(),
      });
      if (response.statusCode === 200) {
        const data = _.omit(response.data.data.data[0], 'id', 'created_at', 'updated_at', 'deleted_at', 'startup_id', 'year', 'type');
        const burnRateData = Object.values(data).map((v) => {
          if (v !== null) {
            return v;
          }
          return null;
        });
        setBurnRate({
          series: [
            {
              name: 'Burn Rate',
              data: burnRateData,
            },
          ],
          options,
        });
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      toast.error(error.message);
    } finally {
    }
  };

  const fetchCACDetails = async () => {
    try {
      const response = await getStartupModule({
        startup_id: details?.details?.id,
        type: AllowedStartupModules.CAC,
        year: (new Date()).getFullYear(),
      });
      if (response.statusCode === 200) {
        const data = _.omit(response.data.data.data[0], 'id', 'created_at', 'updated_at', 'deleted_at', 'startup_id', 'year', 'type');
        const CACData = Object.values(data).map((v) => {
          if (v !== null) {
            return v;
          }
          return null;
        });
        setCac({
          series: [
            {
              name: 'CAC',
              data: CACData,
            },
          ],
          options,
        });
      }
    } catch (error) {
      console.log(error);
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (details?.details?.id) {
      (async () => {
        await fetchStartup();
        await fetchVisitorDetails();
        await fetchMMRDetails();
        await fetchBurnRateDetails();
        await fetchCACDetails();
      })();
    }
  }, [details]);

  return (
    <div className="page-content">
      {startupDeatils && !isLoading && (
      <div className="container-fluid mb-3" style={{ backgroundColor: 'white' }}>
        <div className="p-2 pt-3 pb-3">
          <div className="dashboard_border_box mb-2">
            <div><p className="mb-2 organization_text">{startupDeatils?.name}</p></div>
            <div className=" organization-details p-2">
              <div className="border_right">
                <p className="mb-0 organization-details_text">Email</p>
                <p className="mb-0 organization-profile_details">{startupDeatils?.email}</p>
              </div>
              <div className="border_right">
                <p className="mb-0 organization-details_text">Phone</p>
                <p className="mb-0 organization-profile_details">{startupDeatils?.phone_number}</p>
              </div>
              <div>
                <p className="mb-0 organization-details_text">Founder</p>
                <p className="mb-0 organization-profile_details">{startupDeatils?.founder?.name}</p>
              </div>
            </div>
            <div className="organization-detailsdiv p-2">
              <div className="border_right">
                <p className="mb-0 organization-details_text">Industry</p>
                <p className="mb-0 organization-profile_details">{industry}</p>
              </div>
              <div className="border_right">
                <p className="mb-0 organization-details_text">Stage</p>
                <p className="mb-0 organization-profile_details">{startupDeatils?.stage}</p>
              </div>
              <div className="border_right">
                <p className="mb-0 organization-details_text">Region</p>
                <p className="mb-0 organization-profile_details">{startupDeatils?.region}</p>
              </div>
              <div className="border_right">
                <p className="mb-0 organization-details_text">Money Raising</p>
                <p className="mb-0 organization-profile_details">{startupDeatils?.raising_amount}</p>
              </div>
              <div>
                <p className="mb-0 organization-details_text">Valuation</p>
                <p className="mb-0 organization-profile_details">{parseFloat(startupDeatils?.valuation) * 10}</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-8">
              <div className="row">
                <div className="col-md-12">
                  <div className="dashboard_border_box dashboard_div">
                    <h5 className="mb-0">Problems And Solutions</h5>
                    <p className="mb-0 p-2 organization-profile_details">{startupDeatils?.problem}</p>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="dashboard_border_box mt-2 dashboard_div">
                    <h5 className="mb-0">Product Description</h5>
                    <p className="mb-0 p-2 organization-profile_details">{startupDeatils?.description}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="dashboard_border_box dashboard_div" style={{ height: '178px' }}>
                <h5 className="mb-0 mt-1">Founders</h5>
                {startupDeatils?.teams.length > 0 && startupDeatils?.teams.map((member) => <p className="mb-0 p-2 pt-1 organization-profile_details">{member?.name}</p>)}
              </div>
            </div>
          </div>
          <div className="mt-3 mb-2">
            <table className="dashbord_table">
              <tr>
                <th style={{ maxWidth: '250px', minWidth: '250px' }}>Module</th>
                <th>Jan 24</th>
                <th>Feb 24</th>
                <th>March 24</th>
                <th>April 24</th>
                <th>May 24</th>
                <th>Jun 24</th>
                <th>Jul 24</th>
                <th>Aug 24</th>
                <th>Sep 24</th>
                <th>Oct 24</th>
                <th>Nov 24</th>
                <th>Dec 24</th>
              </tr>
              {startupDeatils?.startup_modules.map((module) => (
                <tr style={{ borderBottom: '1px solid #E3E3E3' }}>
                  <td>{module?.type}</td>
                  <td>{module?.jan}</td>
                  <td>{module?.feb}</td>
                  <td>{module?.mar}</td>
                  <td>{module?.apr}</td>
                  <td>{module?.may}</td>
                  <td>{module?.jun}</td>
                  <td>{module?.jul}</td>
                  <td>{module?.aug}</td>
                  <td>{module?.sep}</td>
                  <td>{module?.oct}</td>
                  <td>{module?.nov}</td>
                  <td>{module?.dec}</td>
                </tr>
              ))}
            </table>
          </div>
          <div className="d-flex gap-2">
            <div className="col-md-6 mt-2 dashboard_border_box">
              <div>
                <p className="mb-2 chart_header">Visitors or Signups</p>
              </div>
              <div id="chart">
                <ReactApexChart
                  series={visitors?.series}
                  options={visitors?.options}
                  type="bar"
                  height={350}
                />
              </div>
            </div>
            <div className="col-md-6 mt-2 dashboard_border_box">
              <div>
                <p className="mb-2 chart_header">MMR</p>
              </div>
              <div id="chart">
                <ReactApexChart
                  series={MMR?.series}
                  options={MMR?.options}
                  type="bar"
                  height={350}
                />
              </div>
            </div>
          </div>
          <div className="d-flex gap-2">
            <div className="col-md-6 mt-2 dashboard_border_box">
              <div>
                <p className="mb-2 chart_header">CAC</p>
              </div>
              <div id="chart">
                <ReactApexChart
                  series={cac?.series}
                  options={cac?.options}
                  type="bar"
                  height={350}
                  width="100%"
                />
              </div>
            </div>
            <div className="col-md-6 mt-2 dashboard_border_box">
              <div>
                <p className=" mb-2 chart_header">Burn Rate</p>
              </div>
              <div id="chart">
                <ReactApexChart
                  series={burnRate?.series}
                  options={burnRate?.options}
                  type="bar"
                  height={350}
                  width="100%"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      )}

      {isLoading && <CircularProgress color="inherit" sx={{ color: 'white' }} size="20px" thickness={4} /> }

    </div>
  );
}

export default SeedsGateDashboard;
