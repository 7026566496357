import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import InvestorForm from '../InvestorForm/InvestorForm';
import InvestorDashboardDisplay from './InvestorDashboardDisplay';
// import {
//   Card, CardBody, CardTitle, CardText, Button,
// } from 'reactstrap';

function InvestorDashboard() {
  const dispatch = useDispatch();
  const history = useNavigate();
  const { details } = useSelector((state) => ({
    details: state.Login.details,
  }));

//   useEffect(() => {

//   }, [details]);
  return (
    <>
      {
        details?.details?.profile_completed ? (
          <InvestorDashboardDisplay />
        ) : (
          <InvestorForm />
        )
    }
    </>
  );
}

export default InvestorDashboard;
