import axios from 'axios';
import qs from 'qs';

// default
axios.defaults.baseURL = process.env.REACT_APP_API_URL;
// content type
axios.defaults.headers.post['Content-Type'] = 'application/json';

// content type
function init() {
  const token = JSON.parse(localStorage.getItem('authUser')) ? JSON.parse(localStorage.getItem('authUser')).token : null;
  if (token) axios.defaults.headers.common.Authorization = `Bearer ${token}`;
}
init();

// intercepting to capture errors
axios.interceptors.response.use(
  (response) => (response.data ? response.data : response),
  (error) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // let message;
    console.log(error, 'error');
    // switch (error.response.status) {
    //   case 404:
    //     error.message = 'Sorry! the data you are looking for could not be found';
    //     break;
    //   default:
    //     error = error?.response?.data ?? error.response.data;
    // }

    return Promise.reject(error.response.data);
  },
);
/**
 * Sets the default authorization
 * @param {*} token
 */
const setAuthorization = (token) => {
  // console.log("Bearer",token)
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
};

class APIClient {
  /**
   * Fetches data from given url
   */

  //  get = (url, params) => {
  //   return axios.get(url, params);
  // };
  get = (url, params) =>
  // let response;

  // let paramKeys = [];

  // if (params) {
  //   Object.keys(params).map(key => {
  //     paramKeys.push(key + '=' + params[key]);
  //     return paramKeys;
  //   });

  //   const queryString = paramKeys && paramKeys.length ? paramKeys.join('&') : "";
  //   response = axios.get(`${url}?${queryString}`, params);
  // } else {
  //   response = axios.get(`${url}`, params);
  // }

  // return response;

    (params
      ? axios.get(`${url}`, {
        params,
        paramsSerializer: (params) => qs.stringify(params, { indices: false }),
      })
      : axios.get(`${url}`, params))
    ;

  /**
   * post given data to url
   */
  create = (url, data) => axios.post(url, data);

  /**
   * Updates data
   */
  update = (url, data) => axios.patch(url, data);

  post = (url, data) => axios.post(url, data);

  put = (url, data) => axios.put(url, data);

  /**
   * Delete
   */
  delete = (url, config) => axios.delete(url, { ...config });
}
const getLoggedinUser = () => {
  const user = localStorage.getItem('authUser');
  if (!user) {
    return null;
  }
  return JSON.parse(user);
};

export {
  APIClient, setAuthorization, getLoggedinUser, init,
};
