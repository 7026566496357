import { APIClient } from '../helpers/api_helper';

const AxiosClient = new APIClient();

const updateInvestorDetails = (payload, id) => AxiosClient.put(`/investor/${id}`, payload);
const investorListing = (query) => AxiosClient.get('/investor', query);
const getInvestorDetails = (id) => AxiosClient.get(`/investor/${id}`);
const canInvestorViewStartup = (params) => AxiosClient.get(`/investor/${params.investor_id}/startup/${params.startup_id}`);

export {
  updateInvestorDetails,
  investorListing,
  getInvestorDetails,
  canInvestorViewStartup,
};
