import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import toast from 'react-hot-toast';
import InvestorDashboardDisplay from './InvestorDashboardDisplay';
import { getInvestorDetails } from '../../api/investor';
import Pages404 from '../Utility/pages-404';
// import {
//   Card, CardBody, CardTitle, CardText, Button,
// } from 'reactstrap';

function StartupInvestorDashboard() {
  const dispatch = useDispatch();
  const history = useNavigate();
  const params = useParams();
  const { details } = useSelector((state) => ({
    details: state.Login.details,
  }));

  document.title = 'Investor Details';
  const [loading, setLoading] = useState(true);
  const [mountCheck, setMountCheck] = useState(false);
  const [investor, setInvestor] = useState();
  const getInvestor = async () => {
    try {
      setLoading(true);
      const response = await getInvestorDetails(params.investor_id);
      setInvestor(response.data.data);
    } catch (e) {
      console.log(e);
      toast.error(e.data.message ?? 'Failed to fetch investor details.');
    } finally {
      setLoading(false);
    }
    setMountCheck(true);
  };
  useEffect(() => {
    getInvestor();
  }, []);

  if (!mountCheck) {
    return <></>;
  }
  return (
    <div className="page-content">
      {!investor && !loading && <Pages404 />}
      {
          investor && !loading && (
          <div className="container-fluid mb-3" style={{ backgroundColor: 'white' }}>
            <div className="p-2 pt-3 pb-3">
              <div className="dashboard_border_box mb-2">
                <div>
                  <p className="mb-2 organization_text">Investor Information</p>
                </div>
                <div className=" organization-details p-2">
                  <div className="border_right">
                    <p className="mb-0 organization-details_text">Name</p>
                    {
                    loading ? (
                      <div className="d-flex justify-content-center">
                        <CircularProgress color="primary" size="50px" thickness={4} />
                      </div>

                    ) : (
                      <p className="mb-0 organization-profile_details">{investor?.name ?? 'N/A'}</p>
                    )
                }
                  </div>
                  <div className="border_right">
                    <p className="mb-0 organization-details_text">Email</p>
                    {
                    loading ? (
                      <div className="d-flex justify-content-center">
                        <CircularProgress color="primary" size="50px" thickness={4} />
                      </div>

                    ) : (
                      <p className="mb-0 organization-profile_details">{investor?.email ?? 'N/A'}</p>
                    )
                }
                  </div>
                  <div>
                    <p className="mb-0 organization-details_text">Phone</p>
                    {
                    loading ? (
                      <div className="d-flex justify-content-center">
                        <CircularProgress color="primary" size="50px" thickness={4} />
                      </div>

                    ) : (
                      <p className="mb-0 organization-profile_details">{investor?.phone_number ?? 'N/A'}</p>
                    )
                }
                  </div>
                </div>
                <div className="organization-details p-2" style={{ borderTop: '1px solid #CCCCCC' }}>
                  <div className="border_right">
                    <p className="mb-0 organization-details_text">Fund Size</p>
                    {
                    loading ? (
                      <div className="d-flex justify-content-center">
                        <CircularProgress color="primary" size="50px" thickness={4} />
                      </div>

                    ) : (
                      <p className="mb-0 organization-profile_details">{investor?.fundSize ?? 'N/A'}</p>
                    )
                }
                  </div>
                  <div className="border_right">
                    <p className="mb-0 organization-details_text">Region</p>
                    {
                    loading ? (
                      <div className="d-flex justify-content-center">
                        <CircularProgress color="primary" size="50px" thickness={4} />
                      </div>

                    ) : (
                      <p className="mb-0 organization-profile_details">{investor?.region ?? 'N/A'}</p>
                    )
                }
                  </div>
                  <div>
                    <p className="mb-0 organization-details_text">Stage</p>
                    {
                    loading ? (
                      <div className="d-flex justify-content-center">
                        <CircularProgress color="primary" size="50px" thickness={4} />
                      </div>

                    ) : (
                      <p className="mb-0 organization-profile_details">{investor?.stage ?? 'N/A'}</p>
                    )
                }
                  </div>
                </div>
                <div className="organization-details p-2" style={{ borderTop: '1px solid #CCCCCC' }}>
                  <div className="border_right">
                    <p className="mb-0 organization-details_text">Ticket Size</p>
                    {
                    loading ? (
                      <div className="d-flex justify-content-center">
                        <CircularProgress color="primary" size="50px" thickness={4} />
                      </div>

                    ) : (
                      <p className="mb-0 organization-profile_details">{investor?.ticket_size_range ?? 'N/A'}</p>
                    )
                }
                  </div>
                  <div>
                    <p className="mb-0 organization-details_text">Area of Investment</p>
                    {
                    loading ? (
                      <div className="d-flex justify-content-center">
                        <CircularProgress color="primary" size="50px" thickness={4} />
                      </div>

                    ) : (
                      <p className="mb-0 organization-profile_details">{investor?.area_of_investment ?? 'N/A'}</p>
                    )
                }
                  </div>
                </div>
              </div>
            </div>
          </div>
          )
      }
    </div>
  );
}

export default StartupInvestorDashboard;
