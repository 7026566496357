import React, { useState } from 'react';
// import {
//   Card, CardBody, CardTitle, CardText, Button,
// } from 'reactstrap';
import { Autocomplete, CircularProgress, Box, TextField } from '@mui/material';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import { handleKeyDown } from '../../helpers/string_helper';
import { updateInvestorDetails } from '../../api/investor';
import { updateDetails } from '../../store/actions';
import { me } from '../../api/auth';

const regions = [
  'USA',
  'Europe',
  'India',
  'Africa',
  'Asia',
  'Middle East',
  'Canada',
  'America',
  'Oceania',
];

function InvestorForm() {
  const dispatch = useDispatch();
  const { details } = useSelector((state) => ({
    details: state.Login.details,
  }));
  const [loading, setLoading] = useState(false);

  document.title = 'Investor Form';

  // first_name,
  // last_name,
  // phone_number,
  // region,
  // stage,
  // fundSize,
  // ticket_size_range,
  // area_of_investment,

  const investorForm = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    validateOnMount: true,

    initialValues: {
      first_name: '',
      last_name: '',
      phone_number: '',
      region: '',
      stage: '',
      fundSize: '',
      ticket_size_range: '',
      area_of_investment: '',
    },
    validationSchema: Yup.object({
      first_name: Yup.string().required('Please Enter Your First Name'),
      last_name: Yup.string().required('Please Enter Your Last Name'),
      phone_number: Yup.string().required('Please Enter Your Contact Information'),
      region: Yup.string().required('Please Enter Your Region'),
      stage: Yup.string().required('Please Enter Your Stage for the Investment'),
      fundSize: Yup.string().required('Please Enter Your Fund Size'),
      ticket_size_range: Yup.string().required('Please Enter Your Ticket Size'),
      area_of_investment: Yup.string().required('Please Enter Your Area of Investment'),
    }),
    // onSubmit: (values) => {
    //   dispatch(loginUser(values, props.router.navigate));
    //   // navigate('/dashboard');
    // },
  });

  const submitInvestorForm = async () => {
    try {
      setLoading(true);
      await updateInvestorDetails({ ...investorForm.values }, details.details.id);
      const response = await me();
      dispatch(updateDetails({ ...response.data, token: details.details.token }));
    } catch (e) {
      toast.error(e.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="page-content">
      <div className="d-flex justify-content-center align-items-center mb-3">
        <span>
          <svg width="36" height="26" viewBox="0 0 36 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.72065 4.5004C13.3207 -1.8996 22.2206 0.500403 25.7206 2.5004C14.9206 3.30073 9.88733 10.8336 8.7207 14.5C14.7207 8 21.7207 5.5 29.7207 6C37.7207 6.5 35.7207 13.5 33.7207 18C32.1125 21.6185 31.8874 24.5 32.7207 26C30.7207 23.5 31.2207 21 32.7207 14.5C33.5794 10.7788 31.4168 9.73764 29.2011 9.69069C27.6235 9.65726 26.4181 10.8903 25.6145 12.2483C22.0385 18.291 14.8795 21.4474 7.72065 21C-0.27935 20.5 -3.77935 12.5004 5.72065 4.5004Z" fill="black" />
          </svg>
        </span>
        <h3 className="mb-0 ms-2">SEEDS GATE</h3>
      </div>
      <div className="container-fluid mb-3" style={{ backgroundColor: 'white' }}>
        <div className="p-2 pt-3 pb-3">
          <div className="d-flex align-items-center gap-2 flex-wrap">
            <div className="d-flex flex-column">
              <TextField
                className="addrequisiton_field"
                // id={`value-${index}`}
                label="First Name"
                name="first_name"
                required
                onChange={investorForm.handleChange}
                onBlur={investorForm.handleBlur}
                value={investorForm.values.first_name}
                variant="outlined"
                // value={row.value}
                // onChange={(event) => handleInputChange(index, 'value', event.target.value)}
                sx={{ width: 550 }}
                error={investorForm.errors.first_name && investorForm.touched.first_name}
                // helperText={investorForm.errors.first_name && investorForm.touched.first_name ? investorForm.errors.first_name : ''}
                fullWidth
              />
              {/* <span className={`${investorForm.errors.first_name && investorForm.touched.first_name ? 'visible ' : 'invisible '} text-danger`}>{investorForm.errors.first_name}</span> */}
            </div>
            <div className="d-flex flex-column">
              <TextField
                className="addrequisiton_field"
                // id={`value-${index}`}
                label="Last Name"
                variant="outlined"
                required
                name="last_name"
                onChange={investorForm.handleChange}
                onBlur={investorForm.handleBlur}
                value={investorForm.values.last_name}
                error={investorForm.errors.last_name && investorForm.touched.last_name}
                // helperText={investorForm.errors.last_name && investorForm.touched.last_name ? investorForm.errors.last_name : ''}
                // value={row.value}
                // onChange={(event) => handleInputChange(index, 'value', event.target.value)}
                sx={{ width: 550 }}
                fullWidth
              />
              {/* <span className={`${investorForm.errors.last_name && investorForm.touched.last_name ? 'visible ' : 'invisible '} text-danger`}>{investorForm.errors.last_name}</span> */}
            </div>
          </div>
          <div className="d-flex align-items-center gap-2 mt-3 flex-wrap">
            <div>
              <TextField
                className="addrequisiton_field"
                // id={`value-${index}`}
                required
                label="Email Address"
                name="email"
                disabled
                value={details?.details?.email ?? ''}
                variant="outlined"
                // value={row.value}
                // onChange={(event) => handleInputChange(index, 'value', event.target.value)}
                sx={{ width: 560 }}
              />
              {/* <p className="" /> */}
              {/* <span className={`${investorForm.errors.last_name && investorForm.touched.last_name ? 'visible ' : 'invisible '} text-danger`}>{investorForm.errors.last_name}</span> */}
            </div>
            <div className="d-flex flex-column">
              <TextField
                className="addrequisiton_field"
                // id={`value-${index}`}
                label="Phone Number"
                required
                onChange={investorForm.handleChange}
                onBlur={investorForm.handleBlur}
                value={investorForm.values.phone_number}
                onKeyDown={handleKeyDown}
                name="phone_number"
                variant="outlined"
                error={investorForm.errors.phone_number && investorForm.touched.phone_number}
                // value={row.value}
                // onChange={(event) => handleInputChange(index, 'value', event.target.value)}
                sx={{ width: 560 }}
              />
              {/* <span className={`${investorForm.errors.phone_number && investorForm.touched.phone_number ? 'visible ' : 'invisible '} text-danger`}>{investorForm.errors.phone_number}</span> */}
            </div>
          </div>
          <div className="d-flex align-items-center gap-2 mt-3 flex-wrap">
            <div className="d-flex flex-column">
              <TextField
                className="addrequisiton_field"
                // id={`value-${index}`}
                label="What is your fund size ?"
                required
                onChange={investorForm.handleChange}
                onBlur={investorForm.handleBlur}
                value={investorForm.values.fundSize}
                name="fundSize"
                error={investorForm.errors.fundSize && investorForm.touched.fundSize}
                variant="outlined"
                // value={row.value}
                // onChange={(event) => handleInputChange(index, 'value', event.target.value)}
                sx={{ width: 560 }}
              />
              {/* <span className={`${investorForm.errors.fundSize && investorForm.touched.fundSize ? 'visible ' : 'invisible '} text-danger`}>{investorForm.errors.fundSize}</span> */}
            </div>
            <div className="d-flex flex-column">
              <Autocomplete
                id="region"
                name="region"
                className="data_field"
                options={regions}
                sx={{ width: 560 }}
                value={investorForm.values.region}
                renderInput={(params) => <TextField {...params} label="Region" required error={investorForm.errors.region && investorForm.touched.region} />}
                onChange={(e, newValue) => {
                  investorForm.setFieldTouched('region', true, true);
                  investorForm.setFieldValue('region', newValue ?? '', true);
                }}
                onBlur={(e) => {
                  if (!investorForm.touched.region) {
                    investorForm.setFieldTouched('region', true, true);
                  }
                }}
              />

              {/* <span className={`${investorForm.errors.region && investorForm.touched.region ? 'visible ' : 'invisible '} text-danger`}>{investorForm.errors.region}</span> */}
            </div>
          </div>
          <div className="d-flex align-items-center gap-2 mt-3 flex-wrap">
            <div className="d-flex flex-column">
              <TextField
                className="addrequisiton_field"
                // id={`value-${index}`}
                label="Which stage do you invest in ??"
                required
                onChange={investorForm.handleChange}
                onBlur={investorForm.handleBlur}
                value={investorForm.values.stage}
                name="stage"
                error={investorForm.errors.stage && investorForm.touched.stage}
                variant="outlined"
                // value={row.value}
                // onChange={(event) => handleInputChange(index, 'value', event.target.value)}
                sx={{ width: 370 }}
                fullWidth
              />
              {/* <span className={`${investorForm.errors.stage && investorForm.touched.stage ? 'visible ' : 'invisible '} text-danger`}>{investorForm.errors.stage}</span> */}
            </div>
            <div className="d-flex flex-column">
              <TextField
                className="addrequisiton_field"
                // id={`value-${index}`}
                label="What is the ticket size range ?"
                required
                onChange={investorForm.handleChange}
                onBlur={investorForm.handleBlur}
                value={investorForm.values.ticket_size_range}
                error={investorForm.errors.ticket_size_range && investorForm.touched.ticket_size_range}
                name="ticket_size_range"
                variant="outlined"
                // value={row.value}
                // onChange={(event) => handleInputChange(index, 'value', event.target.value)}
                sx={{ width: 370 }}
                fullWidth
              />
              {/* <span className={`${investorForm.errors.ticket_size_range && investorForm.touched.ticket_size_range ? 'visible ' : 'invisible '} text-danger`}>{investorForm.errors.ticket_size_range}</span> */}
            </div>
            <div className="d-flex flex-column">
              <TextField
                className="addrequisiton_field"
                // id={`value-${index}`}
                label="Area of investment ?"
                required
                onChange={investorForm.handleChange}
                onBlur={investorForm.handleBlur}
                value={investorForm.values.area_of_investment}
                variant="outlined"
                error={investorForm.errors.area_of_investment && investorForm.touched.area_of_investment}
                name="area_of_investment"
                fullWidth
                // value={row.value}
                // onChange={(event) => handleInputChange(index, 'value', event.target.value)}
                sx={{ width: 370 }}
              />
              {/* <span className={`${investorForm.errors.area_of_investment && investorForm.touched.area_of_investment ? 'visible ' : 'invisible '} text-danger`}>{investorForm.errors.area_of_investment}</span> */}
            </div>
          </div>
          <div style={{ borderTop: '1px solid #CBD7EF' }} className="d-flex justify-content-center align-items-center mt-3">
            <button
              className={`submit_button mt-3 ${investorForm.isValid ? '' : 'bg-secondary'}`}
              disabled={!investorForm.isValid || loading}
              onClick={(e) => { submitInvestorForm(); }}
              style={{ width: '150px' }}
            >
              {loading ? <CircularProgress color='inherit' sx={{color: 'white' }} size={'20px'} thickness={4} /> : <>Submit</>}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InvestorForm;
