import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import withRouter from './Common/withRouter';
// import { useLocation } from "react-router-dom";

function NonAuthLayout(props) {
  const history = useNavigate();
  // const path = useLocation();
  // useEffect(() => {
  //   const title = path.pathname;
  //   let currentage = title.charAt(1).toUpperCase() + title.slice(2);

  //   document.title =
  //     currentage + " | Minible - Responsive Bootstrap 5 Admin Dashboard";
  // }, [path.pathname]);
  const token = JSON.parse(localStorage.getItem('authUser'))?.token;
  useEffect(() => {
    if (token) {
      history(-1);
    }
  }, [token]);
  if (!token) {
    return <>{props.children}</>;
  }
}

NonAuthLayout.propTypes = {
  children: PropTypes.any,
  location: PropTypes.object,
};

export default withRouter(NonAuthLayout);
