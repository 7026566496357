import { useEffect, useState } from 'react';
import Checkbox from '@mui/material/Checkbox';
import { CardBody, Card } from 'reactstrap';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Avatar, CircularProgress } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import profileImg from '../../assets/images/about.jpg';
import { investorListing } from '../../api/investor';
import { MemberIcon } from '../../helpers/member_icon';

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
function ApplyForInvestor() {
  const [investorList, setInvestorList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { details } = useSelector((state) => ({
    details: state.Login?.details,
  }));

  const navigate = useNavigate();
  document.title = 'Apply For Investor';

  const fetchInvestorList = async () => {
    try {
      setIsLoading(true);
      const response = await investorListing({});

      if (response.statusCode === 200) {
        setInvestorList(response.data.data.data);
      }
    } catch (error) {
      console.log(error);
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (details?.details?.id) {
      fetchInvestorList();
    }
  }, [details]);
  return (
    <div className="page-content">
      <div className="container-fluid mb-3">
        <div className="dashboard_outer_div">
          <div className="d-flex justify-content-between align-items-center mb-2">
            <div>
              <h2 className="dashboard_text mb-0">Apply For Investor</h2>
            </div>
            {/* <div className="d-flex justify-content-between align-items-center">
              <div>
                <span>
                  4 Investor Selected
                </span>
              </div>
              <div className='select_check'>
                <span><Checkbox {...label} /></span>
                <span>Select All</span>
              </div>
            </div> */}
          </div>
          <div className="row mt-4">
            {
                isLoading && investorList.length === 0 && (
                <div className="d-flex justify-content-center mt-3">
                  <CircularProgress color="primary" size="50px" thickness={4} />
                </div>
                )
              }
            {
                !isLoading && investorList.length === 0 && (
                <div className="d-flex justify-content-center mt-3">
                  No Investors Available
                </div>
                )
            }
            {
               !isLoading && investorList.length > 0 && investorList.map((investor) => (
                 <div className="col-md-4" key={investor.id}>
                   <Card className="investor_poolard">
                     <div className="profile_border_bottom">
                       <div className="investor_card_background">
                         <div className="check_name d-flex align-items-center">
                           <h4 className="mb-0">{investor?.name ?? 'N/A'}</h4>
                         </div>
                       </div>
                       <div className="investor_profile_outer_div mb-2">
                         {/* <img src={profileImg} className="investor_profile_outer" /> */}
                         {
                          investor?.name ? (
                            <Avatar
                              {...MemberIcon(investor?.name ?? 'Unkown User')}
                              className="align-self-center investor_profile_outer"
                            />
                          )
                            : (
                              // <img
                              //   className="rounded-circle header-profile-user align-self-center"
                              //   src={user4}
                              //   alt="Header Avatar"
                              // />
                              <AccountCircleIcon
                                className="align-self-center investor_profile_outer"
                                sx={{
                                  fontSize: '30px',
                                }}
                              />
                            )
                        }
                       </div>
                     </div>
                     <div className="mt-2 series_details">
                       <p className="mb-2 text-center">{investor?.stage ?? 'N/A'}</p>
                     </div>
                     <div className="d-flex gap-3 flex-wrap p-3">
                       <div className="d-flex">
                         <span><i className="uil-check-circle" /></span>
                         <span className="ms-2 profile_listing">{investor?.area_of_investment ?? 'N/A'}</span>
                       </div>
                       {/* <div className="d-flex">
                         <span><i className="uil-check-circle" /></span>
                         <span className="ms-2 profile_listing"> SAAS</span>
                       </div>
                       <div className="d-flex">
                         <span><i className="uil-check-circle" /></span>
                         <span className="ms-2 profile_listing">Healthcare</span>
                       </div>
                       <div className="d-flex">
                         <span><i className="uil-check-circle" /></span>
                         <span className="ms-2 profile_listing">E-Commerece Platform</span>
                       </div>
                       <div className="d-flex">
                         <span><i className="uil-check-circle" /></span>
                         <span className="ms-2 profile_listing">Logistics</span>
                       </div> */}
                     </div>
                     <div>
                       <button className="investment_button d-flex justify-content-between align-items-center position-relative" onClick={() => {
                        navigate(`/investor/${investor.id}`)
                       }}>
                         <div className="">
                           <span className="me-3">Investment</span>
                           {/* <svg width="25" height="24" viewBox="0 0 25 24" xmlns="http://www.w3.org/2000/svg" fill="#7b99f3">
                             <path d="M21.5459 12.7959L14.7959 19.5459C14.5846 19.7572 14.2979 19.8759 13.9991 19.8759C13.7002 19.8759 13.4135 19.7572 13.2022 19.5459C12.9908 19.3345 12.8721 19.0479 12.8721 18.749C12.8721 18.4501 12.9908 18.1635 13.2022 17.9521L18.0312 13.1249H4.25C3.95163 13.1249 3.66548 13.0064 3.4545 12.7954C3.24353 12.5844 3.125 12.2983 3.125 11.9999C3.125 11.7016 3.24353 11.4154 3.4545 11.2044C3.66548 10.9934 3.95163 10.8749 4.25 10.8749H18.0312L13.2041 6.04492C12.9927 5.83358 12.874 5.54693 12.874 5.24804C12.874 4.94916 12.9927 4.66251 13.2041 4.45117C13.4154 4.23983 13.7021 4.12109 14.0009 4.12109C14.2998 4.12109 14.5865 4.23983 14.7978 4.45117L21.5478 11.2012C21.6527 11.3058 21.7359 11.4302 21.7926 11.5671C21.8493 11.704 21.8784 11.8507 21.8782 11.9989C21.8781 12.1471 21.8486 12.2938 21.7916 12.4305C21.7346 12.5673 21.6511 12.6914 21.5459 12.7959Z" />
                           </svg> */}
                           <p className="mb-1">${investor?.fundSize ?? 'N/A'}</p>
                         </div>
                         <div className="easy_apply_box">
                           <h5 className="mb-0">Easy Apply</h5>
                         </div>
                       </button>
                     </div>
                   </Card>
                 </div>
               ))
            }
            {/* <div className="col-md-4">
            <Card className="investor_poolard">
                <div className='profile_border_bottom'>
                    <div className='investor_card_background'>
                  <div className='check_name d-flex align-items-center'>
                    <span><Checkbox {...label} /></span>
                    <h4 className='mb-0'>Abdulmajeed Alzamil</h4>
                  </div>
                </div>
                <div className='investor_profile_outer_div mb-2'>
                    <img src={profileImg} className='investor_profile_outer' />
                  </div>
                </div>
                <div className="mt-2 series_details">
                  <p className="mb-2 text-center">Series A,Series-pre Series-B</p>
                </div>
                <div className="d-flex gap-3 flex-wrap p-3">
                  <div className="d-flex">
                    <span><i className="uil-check-circle" /></span>
                    <span className='ms-2 profile_listing'>EDtech</span>
                  </div>
                  <div className="d-flex">
                    <span><i className="uil-check-circle" /></span>
                    <span className='ms-2 profile_listing'> SAAS</span>
                  </div>
                  <div className="d-flex">
                    <span><i className="uil-check-circle" /></span>
                    <span className='ms-2 profile_listing'>Healthcare</span>
                  </div>
                  <div className="d-flex">
                    <span><i className="uil-check-circle" /></span>
                    <span className='ms-2 profile_listing'>E-Commerece Platform</span>
                  </div>
                  <div className="d-flex">
                    <span><i className="uil-check-circle" /></span>
                    <span className='ms-2 profile_listing'>Logistics</span>
                  </div>
                </div>
                <div>
                  <button className="investment_button d-flex justify-content-between align-items-center position-relative">
                    <div><span>Investment</span>
                    <p className='mb-1'>$345788</p>
                    </div>
                    <div className='easy_apply_box'>
                    <h5 className='mb-0'>Easy Apply</h5>
                  </div>
                  </button>
                </div>
              </Card>
            </div>
            <div className="col-md-4">
            <Card className="investor_poolard">
                <div className='profile_border_bottom'>
                    <div className='investor_card_background'>
                  <div className='check_name d-flex align-items-center'>
                    <span><Checkbox {...label} /></span>
                    <h4 className='mb-0'>Abdulmajeed Alzamil</h4>
                  </div>
                </div>
                <div className='investor_profile_outer_div mb-2'>
                    <img src={profileImg} className='investor_profile_outer' />
                  </div>
                </div>
                <div className="mt-2 series_details">
                  <p className="mb-2 text-center">Series A,Series-pre Series-B</p>
                </div>
                <div className="d-flex gap-3 flex-wrap p-3">
                  <div className="d-flex">
                    <span><i className="uil-check-circle" /></span>
                    <span className='ms-2 profile_listing'>EDtech</span>
                  </div>
                  <div className="d-flex">
                    <span><i className="uil-check-circle" /></span>
                    <span className='ms-2 profile_listing'> SAAS</span>
                  </div>
                  <div className="d-flex">
                    <span><i className="uil-check-circle" /></span>
                    <span className='ms-2 profile_listing'>Healthcare</span>
                  </div>
                  <div className="d-flex">
                    <span><i className="uil-check-circle" /></span>
                    <span className='ms-2 profile_listing'>E-Commerece Platform</span>
                  </div>
                  <div className="d-flex">
                    <span><i className="uil-check-circle" /></span>
                    <span className='ms-2 profile_listing'>Logistics</span>
                  </div>
                </div>
                <div>
                  <button className="investment_button d-flex justify-content-between align-items-center position-relative">
                    <div><span>Investment</span>
                    <p className='mb-1'>$345788</p>
                    </div>
                    <div className='easy_apply_box'>
                    <h5 className='mb-0'>Easy Apply</h5>
                  </div>
                  </button>
                </div>
              </Card>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ApplyForInvestor;
