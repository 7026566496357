export const handleKeyDown = (event, allRegex = null) => {
  if ([8, 9, 13].includes(event.keyCode)) {
    return;
  }

  const regex = allRegex ?? /^[0-9]+$/;

  if (!regex.test(event.key)) {
    event.preventDefault();
  }
};
