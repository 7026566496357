import { APIClient } from '../helpers/api_helper';

const AxiosClient = new APIClient();

const getStartupDetails = (startup) => AxiosClient.get(`/startup/${startup}`);
const updateStartup = (startup, payload) => AxiosClient.put(`/startup/${startup}`, { ...payload });
const replyRequestStatus = (payload, id) => AxiosClient.put(`/investor-startup-request/${id}`, payload);
const getStartupModule = (query) => AxiosClient.get('/startup-module', query);
const getStartupList = (query) => AxiosClient.get('/startup', query);
const sendStartupRequest = (params) => AxiosClient.post(`/investor/${params.investor}/startup/${params.startup}`);

export {
  getStartupDetails,
  updateStartup,
  replyRequestStatus,
  getStartupModule,
  getStartupList,
  sendStartupRequest,
};
