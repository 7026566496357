import { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { CardBody, Card } from 'reactstrap';
// import {
//   Card, CardBody, CardTitle, CardText, Button,
// } from 'reactstrap';
// import TuneIcon from '@mui/icons-material/Tune';
import { useDispatch, useSelector } from 'react-redux';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import toast from 'react-hot-toast';
import { CircularProgress } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import { Link, Navigate } from 'react-router-dom';
import { investorListing } from '../../api/investor';
import { MemberIcon } from '../../helpers/member_icon';
import { InvestorRequestStatus } from '../../constants/common';
import { replyRequestStatus } from '../../api/startup';

function InvestorRequestListing() {
  const dispatch = useDispatch();
  const { details } = useSelector((state) => ({
    details: state.Login.details,
  }));
  const [investorRequestList, setInvestorRequestList] = useState([]);
  const [loading, setLoading] = useState(false);

  document.title = 'Investor Request List';

  const handleStartupAction = async (investor, status) => {
    try {
      setLoading(true);
      console.log(investor);
      const response = await replyRequestStatus({
        status,
      }, investor.investor_requests[0].id);
      console.log(response.data, 'request investor');
      if (InvestorRequestStatus.REJECTED === status) {
        setInvestorRequestList((prev) => [...prev.filter((p) => p.id !== investor.id)]);
      } else if (InvestorRequestStatus.APPROVED === status) {
        setInvestorRequestList((prev) => {
          const i = prev.findIndex((p) => p.id === investor.id);
          prev[i].investor_requests[0].status = InvestorRequestStatus.APPROVED;
          return [...prev];
        });
      }
      toast.success(response.data.message);
    } catch (e) {
      console.log(e);
      toast.error('Failed to update startup reply to request.');
    } finally {
      setLoading(false);
    }
  };
  const getInvestorRequestListing = async () => {
    try {
      setLoading(true);
      const response = await investorListing({
        requested_startup_id: details?.details?.id,
      });
      console.log(response.data, 'request investor');

      setInvestorRequestList(response.data.data.data);
    } catch (e) {
      console.log(e);
      toast.error('Failed to procure investor request listing.');
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (details) {
      getInvestorRequestListing();
    } else {
      setLoading(true);
      setInvestorRequestList([]);
    }
  }, [details]);
  return (
    <div className="page-content">
      <div className="container-fluid mb-3">
        <Card>
          <CardBody>
            <div>
              <div className="investor_list">
                <h3 className="mb-3">Investor Requests</h3>
              </div>

              {
                loading && investorRequestList.length === 0 && (
                <div className="d-flex justify-content-center mt-3">
                  <CircularProgress color="primary" size="50px" thickness={4} />
                </div>
                )
              }
              {
                !loading && investorRequestList.length === 0 && (
                <div className="d-flex justify-content-center mt-3">
                  No Requests
                </div>
                )
              }
              {
                !loading && investorRequestList.length > 0 && investorRequestList.map((investorRequest) => (
                  <div className="d-flex justify-content-between align-items-center mt-2" key={investorRequest.id}>
                    <div className="d-flex">
                      <div className="me-2">
                        {
                          investorRequest?.name ? (
                            <Avatar
                              {...MemberIcon(investorRequest?.name ?? 'Unkown User')}
                              className="align-self-center"
                            />
                          )
                            : (
                          // <img
                          //   className="rounded-circle header-profile-user align-self-center"
                          //   src={user4}
                          //   alt="Header Avatar"
                          // />
                              <AccountCircleIcon
                                className="align-self-center"
                                sx={{
                                  fontSize: '30px',
                                }}
                              />
                            )
                }
                      </div>
                      <div className="profile_imgouter">
                        <Link to={`/investor/${investorRequest.id}`}><h5 className="mb-0 link-class">{investorRequest?.name ?? 'N/A'}</h5></Link>
                        <p className="mb-0">{investorRequest?.email ?? 'N/A'}</p>
                      </div>
                    </div>
                    {
                      investorRequest.investor_requests.length > 0 && investorRequest.investor_requests[0].status === InvestorRequestStatus.PENDING && (
                      <div className="d-flex justify-content-end">
                        <button className="approve_button" disabled={loading} onClick={() => { handleStartupAction(investorRequest, InvestorRequestStatus.APPROVED); }}>Approve</button>
                        <button className="decline_button ms-2" disabled={loading} onClick={() => { handleStartupAction(investorRequest, InvestorRequestStatus.REJECTED); }}>Reject</button>
                      </div>
                      )
                    }
                    {
                      investorRequest.investor_requests.length > 0 && investorRequest.investor_requests[0].status === InvestorRequestStatus.APPROVED && (
                      <div className="d-flex justify-content-center">
                        <div className="approve_button" >{investorRequest.investor_requests[0].status}</div>
                      </div>
                      )
                    }
                  </div>
                ))
              }
              {/* <div className="d-flex justify-content-between align-items-center mt-2">
                <div className="d-flex">
                  <div className=" me-2">
                    <img src={profileImg} className="rounded rounded_profile " />
                  </div>
                  <div className="profile_imgouter">
                    <h5 className="mb-0">Ayush Kappor</h5>
                    <p className="mb-0">Mr.</p>
                  </div>
                </div>
                <div className="d-flex justify-content-end">
                  <button className="approve_button">Approve</button>
                  <button className="decline_button ms-2">Decline</button>
                </div>
              </div>
              <div className="d-flex justify-content-between align-items-center mt-2">
                <div className="d-flex">
                  <div className="me-2">
                    <img src={profileImg} className="rounded rounded_profile" />
                  </div>
                  <div className="profile_imgouter">
                    <h5 className="mb-0">Ayush Kappor</h5>
                    <p className="mb-0">Mr.</p>
                  </div>
                </div>
                <div className="d-flex justify-content-end">
                  <button className="approve_button">Approve</button>
                  <button className="decline_button ms-2">Decline</button>
                </div>
              </div> */}
            </div>
          </CardBody>
        </Card>
      </div>
    </div>
  );
}

export default InvestorRequestListing;
