import React, { useState, useEffect } from 'react';
import { Card } from 'reactstrap';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { CircularProgress } from '@mui/material';
import {
  useNavigate,
} from 'react-router-dom';
import poolInvestor from '../../assets/images/Group 1000004097.png';
import { StartupRequestStatus, StatusColor } from '../../helpers/StartupRequestStatus';
import { getStartupList, sendStartupRequest } from '../../api/startup';
import { InvestorRequestStatus } from '../../constants/common';
import { canInvestorViewStartup } from '../../api/investor';

function StartupPoolList() {
  const [startupList, setStartupList] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingForRequest, setIsLoadingForRequest] = useState(false);
  const { details } = useSelector((state) => ({
    details: state.Login?.details,
  }));

  const navigate = useNavigate();
  document.title = 'Startup Pool';

  const fetchStartupList = async () => {
    try {
      setIsLoading(true);
      const response = await getStartupList({
        requested_investor_id: details?.details?.id,
      });
      if (response.statusCode === 200) {
        setStartupList(response.data.data.data);
      }
    } catch (error) {
      console.log(error);
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (details?.details?.id) {
      fetchStartupList();
    }
  }, [details]);

  const handleRequest = async (id) => {
    try {
      setIsLoadingForRequest(true);
      const response = await sendStartupRequest({
        startup: id,
        investor: details?.details?.id,
      });
      if (response.statusCode === 200) {
        toast.success(response.data.message);
        fetchStartupList();
      }
    } catch (error) {
      console.log(error);
      toast.error(error.message);
    } finally {
      setIsLoadingForRequest(false);
    }
  };

  const checkViewPermission = async (id) => {
    try {
      const response = await canInvestorViewStartup({
        startup_id: id,
        investor_id: details?.details?.id,
      });
      if (response.statusCode === 200) {
        navigate(`/startup/${id}`);
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.data?.message);
    }
  };

  return (
    <div className="page-content">
      <div className="container-fluid mb-3">
        <div className="dashboard_outer_div">
          <div className="inverstor_outer">
            <div>
              <h2 className="dashboard_text">Startup Pool</h2>
            </div>
          </div>
          {startupList && startupList.length > 0 && !isLoading && (
          <div className="row">
            {startupList.map((item) => (
              <div className="col-md-4">
                <Card className="investor_poolard">
                  <div className="investor_inner_card">
                    <img src={poolInvestor} alt="" style={{ borderRadius: '10px 10px 0px 0px' }} />
                  </div>
                  <div className="mt-2 series_details" style={{ height: '30px' }}>
                    <p className="mb-2 text-center">{item?.name ?? 'N/A'}</p>
                  </div>
                  <div className="container p-3 card_details_text" style={{ height: '80px' }}>
                    <h4 className="mb-2">Description</h4>
                    <p className="mb-0 mt-2  text-truncate" style={{ height: '20px' }}>{item?.description ?? 'N/A'}</p>
                  </div>
                  <div>
                    <button
                      type="submit"
                      disabled={isLoadingForRequest || (item?.startup_requests.length > 0 && item?.startup_requests[0]?.status === InvestorRequestStatus.PENDING)}
                      className={`request_databutton ${item?.startup_requests.length === 0 ? 'default_request_color' : StatusColor(item?.startup_requests[0])}`}
                      onClick={() => ((item.startup_requests.length > 0 && item?.startup_requests[0]?.status === InvestorRequestStatus.APPROVED) ? checkViewPermission(item?.id) : handleRequest(item?.id))}
                    >
                      {item?.startup_requests.length === 0 ? 'Request Dataroom' : StartupRequestStatus(item?.startup_requests[0]) }
                      { (item?.startup_requests.length === 0 || (item?.startup_requests.length > 0 && item?.startup_requests[0]?.status !== InvestorRequestStatus.PENDING))
                       && (
                       <span className="ms-2">
                         <svg width="25" height="24" viewBox="0 0 25 24" xmlns="http://www.w3.org/2000/svg" fill={`${item?.startup_requests.length === 0 ? '#7b99f3' : StatusColor(item?.startup_requests[0], 'svg')}`}>
                           <path d="M21.5459 12.7959L14.7959 19.5459C14.5846 19.7572 14.2979 19.8759 13.9991 19.8759C13.7002 19.8759 13.4135 19.7572 13.2022 19.5459C12.9908 19.3345 12.8721 19.0479 12.8721 18.749C12.8721 18.4501 12.9908 18.1635 13.2022 17.9521L18.0312 13.1249H4.25C3.95163 13.1249 3.66548 13.0064 3.4545 12.7954C3.24353 12.5844 3.125 12.2983 3.125 11.9999C3.125 11.7016 3.24353 11.4154 3.4545 11.2044C3.66548 10.9934 3.95163 10.8749 4.25 10.8749H18.0312L13.2041 6.04492C12.9927 5.83358 12.874 5.54693 12.874 5.24804C12.874 4.94916 12.9927 4.66251 13.2041 4.45117C13.4154 4.23983 13.7021 4.12109 14.0009 4.12109C14.2998 4.12109 14.5865 4.23983 14.7978 4.45117L21.5478 11.2012C21.6527 11.3058 21.7359 11.4302 21.7926 11.5671C21.8493 11.704 21.8784 11.8507 21.8782 11.9989C21.8781 12.1471 21.8486 12.2938 21.7916 12.4305C21.7346 12.5673 21.6511 12.6914 21.5459 12.7959Z" />
                         </svg>
                       </span>
                       )}
                      {isLoadingForRequest && <CircularProgress color="inherit" sx={{ color: 'white' }} size="20px" thickness={4} /> }
                    </button>
                  </div>
                </Card>
              </div>
            ))}
          </div>
          )}
          {startupList && startupList.length === 0 && (
            <div className="row">
              No Data Found
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default StartupPoolList;
