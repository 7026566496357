import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import toast from 'react-hot-toast';
import _ from 'lodash';
import { CircularProgress } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { AllowedStartupModules } from '../../constants/startup';
import { getStartupDetails, getStartupModule } from '../../api/startup';
import { canInvestorViewStartup } from '../../api/investor';
import Pages404 from '../Utility/pages-404';

function StartupDetais() {
  const options = {
    chart: {
      type: 'bar',
      width: 200,
      height: 300,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
        endingShape: 'rounded',
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    xaxis: {
      categories: ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'],
    },
    yaxis: {
      title: {
        text: '',
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter(val) {
          return `${val}`;
        },
      },
    },
  };
  document.title = 'Startup Details';

  const [startupDeatils, setStartupDeatisl] = useState();
  const [mountedCheck, setMountCheck] = useState(false);
  const [industry, setIndustry] = useState();
  const [visitors, setVisitors] = useState({
    series: [
      {
        name: AllowedStartupModules.VISITORS,
        data: [],
      },
    ],
    options,
  });
  const [MMR, setMMR] = useState({
    series: [
      {
        name: AllowedStartupModules.MMR,
        data: [],
      },
    ],
    options,
  });
  const [burnRate, setBurnRate] = useState({
    series: [
      {
        name: AllowedStartupModules.BURN_RATE,
        data: [],
      },
    ],
    options,
  });
  const [cac, setCac] = useState({
    series: [
      {
        name: AllowedStartupModules.CAC,
        data: [],
      },
    ],
    options,
  });
  const [isLoading, setIsLoading] = useState(false);
  const params = useParams();
  const [hasViewPermission, setHasViewPermission] = useState(false);
  const { details } = useSelector((state) => ({
    details: state.Login?.details,
  }));

  const fetchStartup = async () => {
    try {
      setIsLoading(true);
      const response = await getStartupDetails(params.startup_id);
      if (response.statusCode === 200) {
        setStartupDeatisl(response.data.data);
        if (response.data.data?.industry) {
          setIndustry(Object.values(response.data.data?.industry).join(', '));
        }
      }
    } catch (error) {
      console.log(error);
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchModuleDetails = async () => {
    try {
      const response = await getStartupModule({
        startup_id: params.startup_id,
        year: (new Date()).getFullYear(),
      });
      if (response.statusCode === 200) {
        if (response.data.data.data.length > 0) {
          response.data.data.data.forEach((module) => {
            switch (module.type) {
              case AllowedStartupModules.VISITORS: {
                const data = _.omit(module, 'id', 'created_at', 'updated_at', 'deleted_at', 'startup_id', 'year', 'type');
                const visitorsData = Object.values(data).map((v) => {
                  if (v !== null) {
                    return v;
                  }
                  return null;
                });
                setVisitors({
                  ...visitors,
                  series: [
                    {
                      name: AllowedStartupModules.VISITORS,
                      data: visitorsData,
                    },
                  ],
                });
                break;
              }
              case AllowedStartupModules.MMR: {
                const data = _.omit(module, 'id', 'created_at', 'updated_at', 'deleted_at', 'startup_id', 'year', 'type');
                const mmrData = Object.values(data).map((v) => {
                  if (v !== null) {
                    return v;
                  }
                  return null;
                });
                setMMR({
                  ...MMR,
                  series: [
                    {
                      name: AllowedStartupModules.MMR,
                      data: mmrData,
                    },
                  ],
                });
                break;
              }
              case AllowedStartupModules.BURN_RATE: {
                const data = _.omit(module, 'id', 'created_at', 'updated_at', 'deleted_at', 'startup_id', 'year', 'type');
                const burnRateData = Object.values(data).map((v) => {
                  if (v !== null) {
                    return v;
                  }
                  return null;
                });
                setBurnRate({
                  ...burnRate,
                  series: [
                    {
                      name: AllowedStartupModules.BURN_RATE,
                      data: burnRateData,
                    },
                  ],
                });
                break;
              }
              case AllowedStartupModules.CAC: {
                const data = _.omit(module, 'id', 'created_at', 'updated_at', 'deleted_at', 'startup_id', 'year', 'type');
                const CACData = Object.values(data).map((v) => {
                  if (v !== null) {
                    return v;
                  }
                  return null;
                });
                setCac({
                  ...cac,
                  series: [
                    {
                      name: AllowedStartupModules.CAC,
                      data: CACData,
                    },
                  ],
                });
                break;
              }
              default:
                break;
            }
          });
        }
      }
    } catch (error) {
      console.log(error);
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const checkViewPermission = async () => {
    try {
      const response = await canInvestorViewStartup({
        startup_id: params.startup_id,
        investor_id: details?.details?.id,
      });
      if (response.statusCode === 200) {
        await fetchStartup();
        await fetchModuleDetails();
        setHasViewPermission(true);
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.data?.message);
    }
    setMountCheck(true);
  };

  useEffect(() => {
    if (params.startup_id && details?.details?.id) {
      checkViewPermission();
    }
  }, [details]);

  if (!mountedCheck) {
    return <></>
  }

  return (
    <div className="page-content">
      {startupDeatils && !isLoading && hasViewPermission && (
      <div className="container-fluid mb-3" style={{ backgroundColor: 'white' }}>
        <div className="p-2 pt-3 pb-3">
          <div className="dashboard_border_box mb-2">
            <div><p className="mb-2 organization_text">{startupDeatils?.name ?? 'N/A'}</p></div>
            <div className=" organization-details p-2">
              <div className="border_right">
                <p className="mb-0 organization-details_text">Email</p>
                <p className="mb-0 organization-profile_details">{startupDeatils?.email}</p>
              </div>
              <div className="border_right">
                <p className="mb-0 organization-details_text">Phone</p>
                <p className="mb-0 organization-profile_details">{startupDeatils?.phone_number ?? 'N/A'}</p>
              </div>
              <div>
                <p className="mb-0 organization-details_text">Founder</p>
                <p className="mb-0 organization-profile_details">{startupDeatils?.founder?.name ?? 'N/A'}</p>
              </div>
            </div>
            <div className="organization-detailsdiv p-2">
              <div className="border_right">
                <p className="mb-0 organization-details_text">Industry</p>
                <p className="mb-0 organization-profile_details">{industry ?? 'N/A'}</p>
              </div>
              <div className="border_right">
                <p className="mb-0 organization-details_text">Stage</p>
                <p className="mb-0 organization-profile_details">{startupDeatils?.stage ?? 'N/A'}</p>
              </div>
              <div className="border_right">
                <p className="mb-0 organization-details_text">Region</p>
                <p className="mb-0 organization-profile_details">{startupDeatils?.region ?? 'N/A'}</p>
              </div>
              <div className="border_right">
                <p className="mb-0 organization-details_text">Money Raising</p>
                <p className="mb-0 organization-profile_details">{startupDeatils?.raising_amount ?? 'N/A'}</p>
              </div>
              <div>
                <p className="mb-0 organization-details_text">Valuation</p>
                <p className="mb-0 organization-profile_details">{startupDeatils?.valuation ? parseFloat(startupDeatils?.valuation) * 10 : 'N/A'}</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-8">
              <div className="row">
                <div className="col-md-12">
                  <div className="dashboard_border_box dashboard_div">
                    <h5 className="mb-0">Problems And Solutions</h5>
                    <p className="mb-0 p-2 organization-profile_details">{startupDeatils?.problem ?? 'N/A'}</p>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="dashboard_border_box mt-2 dashboard_div">
                    <h5 className="mb-0">Product Description</h5>
                    <p className="mb-0 p-2 organization-profile_details">{startupDeatils?.description ?? 'N/A'}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="dashboard_border_box dashboard_div" style={{ height: '178px' }}>
                <h5 className="mb-0 mt-1">Founders</h5>
                {startupDeatils?.teams.length > 0 && startupDeatils?.teams.map((member) => <p className="mb-0 p-2 pt-1 organization-profile_details">{member?.name}</p>)}
              </div>
            </div>
          </div>
          <div className="mt-3 mb-2">
            <table className="dashbord_table">
              <tr>
                <th style={{ maxWidth: '250px', minWidth: '250px' }}>Module</th>
                <th>Jan 24</th>
                <th>Feb 24</th>
                <th>March 24</th>
                <th>April 24</th>
                <th>May 24</th>
                <th>Jun 24</th>
                <th>Jul 24</th>
                <th>Aug 24</th>
                <th>Sep 24</th>
                <th>Oct 24</th>
                <th>Nov 24</th>
                <th>Dec 24</th>
              </tr>
              {startupDeatils?.startup_modules.map((module) => (
                <tr style={{ borderBottom: '1px solid #E3E3E3' }}>
                  <td>{module?.type}</td>
                  <td>{module?.jan}</td>
                  <td>{module?.feb}</td>
                  <td>{module?.mar}</td>
                  <td>{module?.apr}</td>
                  <td>{module?.may}</td>
                  <td>{module?.jun}</td>
                  <td>{module?.jul}</td>
                  <td>{module?.aug}</td>
                  <td>{module?.sep}</td>
                  <td>{module?.oct}</td>
                  <td>{module?.nov}</td>
                  <td>{module?.dec}</td>
                </tr>
              ))}
            </table>
          </div>
          <div className="d-flex gap-2">
            <div className="col-md-6 mt-2 dashboard_border_box">
              <div>
                <p className="mb-2 chart_header">Visitors or Signups</p>
              </div>
              <div id="chart">
                <ReactApexChart
                  series={visitors?.series}
                  options={visitors?.options}
                  type="bar"
                  height={350}
                />
              </div>
            </div>
            <div className="col-md-6 mt-2 dashboard_border_box">
              <div>
                <p className="mb-2 chart_header">MMR</p>
              </div>
              <div id="chart">
                <ReactApexChart
                  series={MMR?.series}
                  options={MMR?.options}
                  type="bar"
                  height={350}
                />
              </div>
            </div>
          </div>
          <div className="d-flex gap-2">
            <div className="col-md-6 mt-2 dashboard_border_box">
              <div>
                <p className="mb-2 chart_header">CAC</p>
              </div>
              <div id="chart">
                <ReactApexChart
                  series={cac?.series}
                  options={cac?.options}
                  type="bar"
                  height={350}
                  width="100%"
                />
              </div>
            </div>
            <div className="col-md-6 mt-2 dashboard_border_box">
              <div>
                <p className=" mb-2 chart_header">Burn Rate</p>
              </div>
              <div id="chart">
                <ReactApexChart
                  series={burnRate?.series}
                  options={burnRate?.options}
                  type="bar"
                  height={350}
                  width="100%"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      )}

      {isLoading && <CircularProgress color="inherit" sx={{ color: 'white' }} size="20px" thickness={4} /> }
      {!hasViewPermission && <Pages404 />}
    </div>
  );
}

export default StartupDetais;
