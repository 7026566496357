const AllowedStartupRegion = [
  'USA',
  'Europe',
  'India',
  'Africa',
  'Asia',
  'Middle East',
  'Canada',
  'America',
  'Oceania',
];

const AllowedStartupStage = {
  PRE_PRODUCT: 'Pre-Product',
  MVP: 'MVP',
  POST_PRODUCT: 'Post-Prouct',
  GROWTH: 'Growth',
};

const AllowedStartupRaisingAmount = {
  FAMILY_FRIENDS: '$100K-$250K Family & Friends',
  ANGEL_ROUND: '$250K-$750K Angel Round',
  SEEDS_ROUND: '$750K-$1.5M Seeds Round',
  SERIES_A: '$1.5M+ Series A or later',
};

const AllowedStartupIndustry = {
  BLOCKCHAIN: 'Blockchain',
  ENTERTAINMENT: 'Entertainment',
  CLEAR_ENERY: 'Clear Energy',
  HEALTH_AND_WELLNESS: 'Health and Wellness',
  TRANSPORTATION: 'Transportation',
  FINANCE: 'Finance',
  TRAVEL: 'Travel',
  EDUCATION: 'Education',
  RETAIL_TECHNOLOGY: 'Retail Technology',
  OTHERS: 'Others',
};

const AllowedStartupModulesList = [
  'Blockchain',
  'Entertainment',
  'Clear Energy',
  'Health and Wellness',
  'Transportation',
  'Finance',
  'Travel',
  'Education',
  'Retail Technology',
  'Others',
];

const AllowedStartupModules = {
  VISITORS: 'Visitors',
  MMR: 'MMR',
  BURN_RATE: 'Burn Rate',
  CAC: 'CAC',
};

const InvestorStartupRequestStatus = {
  PENDING: 'Pending',
  APPROVED: 'Approved',
  REJECTED: 'Rejected',
};

export {
  AllowedStartupRegion,
  AllowedStartupStage,
  AllowedStartupRaisingAmount,
  AllowedStartupIndustry,
  AllowedStartupModulesList,
  AllowedStartupModules,
  InvestorStartupRequestStatus,
};
